﻿$black: #28292b;
// $orange: #ff911e;
$orange: #be4018;
$sand: #f6f3eb;
$sanddark: #cdcbc5;
$bronze: #afa08d;
$beige: #ebe8e1;
$pink: #fadcc4;
$space: 120px;

/* Media query */
$xxs-max: "only screen and (max-width: 384px)";
$xs-max: "only screen and (max-width: 767px)";
$sm-max: "only screen and (max-width: 991px)";
$md-max: "only screen and (max-width: 1199px)";
$xl-min: "only screen and (min-width: 1600px)";

.compensate-for-scrollbar {
  margin: 0 auto !important;
}

/* -------------------------------------------------- 
  Colors
-------------------------------------------------- */
.orange {
  color: $orange;
}

.bg-orange {
  background-color: $orange;
}

/* -------------------------------------------------- 
  Shadows
-------------------------------------------------- */
.shadow {
  box-shadow: 20px 50px 60px -40px rgba(0, 0, 0, 0.4);
}

/* -------------------------------------------------- 
  Flex row
-------------------------------------------------- */
.row.flex-row {
  display: flex;
  flex-wrap: wrap;
}

/* -------------------------------------------------- 
  Layout
-------------------------------------------------- */
.container-fluid {
  max-width: 1500px;

  @media (min-width: 768px) and (max-width: 1399px) {
    $padding: 50px;
    padding-right: $padding;
    padding-left: $padding;
  }

  @media (min-width: 1400px) and (max-width: 1760px) {
    $padding: 80px;
    padding-right: $padding;
    padding-left: $padding;
  }
}

/* -------------------------------------------------- 
  Bar
-------------------------------------------------- */
.title-has-quotes {
  padding-bottom: 15px;
  position: relative;
  margin: 0 auto;
  display: inline-block;
  padding-bottom: 20px;
  margin-bottom: 10px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 10px;
    background-color: #be4018;
  }

  @media (max-width: 991px) {
    margin: 0;

    &:after {
      display: none;
    }
  }
}

.section-orange {
  .title-has-quotes {
    &:after {
      background-color: #fff !important;
    }
  }
}

/* -------------------------------------------------- 
  Section
-------------------------------------------------- */
.section {
  padding-top: $space * 0.75;
  padding-bottom: $space * 0.75;
  text-align: center;

  &-orange {
    @extend .bg-orange;
    @extend .section;
    color: white;

    a {
      color: white;
    }

    .title-quotes {
      fill: white;
      display: none;
    }
  }

  &-beige {
    @extend .section;
    background-color: $beige;
    color: $black;

    a {
      color: black;
    }

    strong {
      color: $orange;
    }

    .title-quotes {
      fill: $black;
    }
  }

  &-abstract {
    @extend .spacer;
  }

  @media (max-width: 1199px) {
    padding-top: $space * 0.75;
    padding-bottom: $space * 0.75;
  }

  @media (max-width: 767px) {
    padding-top: $space / 2;
    padding-bottom: $space / 2;

    .title-quotes {
      height: 30px;
    }
  }

  @media (max-width: 384px) {
    padding-top: $space / 3;
    padding-bottom: $space / 3;
  }
}
// .section

/* -------------------------------------------------- 
  Spacers
-------------------------------------------------- */
.spacer {
  margin-bottom: 40px;

  @media #{$xs-max} {
    margin-bottom: $space / 4;
  }

  &-xs {
    margin-bottom: $space / 6;
  }

  &-sm {
    margin-bottom: $space / 4;
  }

  &-lg {
    margin-bottom: $space / 4;

    @media (min-width: 768px) {
      margin-bottom: $space / 2;
    }

    @media (min-width: 1200px) {
      margin-bottom: $space;
    }
  }
  // Mobile
  &-mobile {
    @media #{$xs-max} {
      margin-bottom: $space / 3;
    }
  }
}

/* -------------------------------------------------- 
  Tabella su due colonne
-------------------------------------------------- */
.table-template {
  display: none;
}

/* -------------------------------------------------- 
  Article
-------------------------------------------------- */
.article {
  text-align: center;
}

/* -------------------------------------------------- 
  Utils
-------------------------------------------------- */
.list-inline {
  li {
    display: inline;
  }
}

.list-reset {
  padding: 0;

  li {
    list-style: none;
  }
}

.border-orange {
  border: 2px solid $orange;

  @media #{$xs-max} {
    border: 0;
  }
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

/* -------------------------------------------------- 
  Mixins
-------------------------------------------------- */
@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin square($size) {
  @include size($size, $size);
}

/* -------------------------------------------------- 
  Bootstrap cols xxs
-------------------------------------------------- */
@for $i from 1 through 12 {
  .col-xxs-#{$i} {
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
  }
}

@media (max-width: 384px) {
  @for $i from 1 through 12 {
    .col-xxs-#{$i} {
      float: left;
      width: 100% * ($i/12);
    }
  }
}

/* -------------------------------------------------- 
  Bootstrap cols xxl
-------------------------------------------------- */
@for $i from 1 through 12 {
  .col-xl-#{$i} {
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
  }

  @media (min-width: 1600px) {
    .col-xl-#{$i} {
      float: left;
      width: 100% * ($i/12);
    }

    .col-xl-offset-#{$i} {
      margin-left: 100% * ($i/12);
    }
  }
}

/* -------------------------------------------------- 
  Body
-------------------------------------------------- */
body {
    max-width: 1920px;
    min-width: 320px;
    margin: 0 auto;
    overflow-x: hidden;

    &.fancybox-iosfix{
        position:static;
    }
}

/* -------------------------------------------------- 
  Virgolette
-------------------------------------------------- */
.svg-wrapper {
  display: none;
}

/* -------------------------------------------------- 
  Share row
-------------------------------------------------- */
body {
  &:-webkit-scrollbar {
    width: 1em;
  }

  &:-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &:-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}

/* -------------------------------------------------- 
  Icone
-------------------------------------------------- */
.p-icon {
  $size: 42px;
  @include square($size);
  display: inline-block;
  background-image: url(/img/menu/icons.png);
  background-size: 100%;

  &-search {
    background-position: 0 0;
  }

  &-stores {
    background-position: -$size 0;
  }
}

/* -------------------------------------------------- 
  Misc
-------------------------------------------------- */
.header-padding {
  height: 90px;

  &--orange {
    background-color: $orange;
  }

  @media #{$xs-max} {
    height: $space / 3;
  }
}

.x-close {
  @include square(24px);
  display: block;
  background-image: url(/img/close.png);
  background-size: cover;

  &--white {
    background-image: url(/img/close-white.png);
  }
}

.madeinusa {
  width: 160px;
}

/* -------------------------------------------------- 
  Nl Mobile/Desk
-------------------------------------------------- */
.nl-mobile {
  display: none !important;

  @media #{$sm-max} {
    display: none !important;
  }
}

.nl-desk {
  display: block;

  @media #{$sm-max} {
    display: none;
  }
}

/* -------------------------------------------------- 
  Editoriale
-------------------------------------------------- */
.editorial {
  text-align: left;

  h3 {
    margin-bottom: 40px;
  }

  img {
    @extend .shadow;
    max-width: 100%;
  }

  &__image {
    text-align: right;
    padding-right: 0;
    padding-left: 0;

    &.col-sm-push-6 {
      padding-left: 0;
    }
  }

  &-title {
    @extend .title;
    @extend .title-lg;
    margin-bottom: 10px;
    padding-bottom: 20px;
    padding-right: 20%;
    position: relative;
    // bar left
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 50px;
      height: 10px;
      background-color: $orange;

      @media #{$md-max} {
        bottom: -12px;
      }
    }
  }

  .editorial-row-reverse {
    // bar right
    .editorial-title:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: auto;
      right: 0;
      width: 50px;
      height: 10px;
      background-color: $orange;

      @media #{$md-max} {
        bottom: -12px;
      }

      @media #{$xs-max} {
        left: 0;
      }
    }
  }

  &-copy {
    padding-left: 0;
    padding-top: 30px;

    p {
      padding: 0 0 20px;
    }
  }

  &-row {
    position: relative;
    /*margin-bottom: $space / 2;*/
    .text {
      padding: 4% 5%;
    }

    &-reverse {
      text-align: right;

      .editorial-title {
        padding-right: 0;
        padding-left: 30%;
      }

      .editorial-copy {
        padding-right: 0;
        padding-left: 0;
      }

      @media #{$xs-max} {
        text-align: left;
      }
    }
    // &-reverse
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  // &-row
  &-square {
    display: none;
    $size: 240px;
    @include square($size);
    background: $orange;
    position: relative;
    bottom: -$size/2;
    left: 50%;
    clear: both;
    top: -$size/2;
    margin-bottom: -$size;
    transform: translateX(-50%);
    z-index: -1;

    @media #{$md-max} {
      $size: 200px;
      @include square($size);
      bottom: -$size/2;
      top: -$size/2;
      margin-bottom: -$size;
    }
  }

  @media #{$md-max} {
    img {
      margin: 0 auto;
      display: block;
      max-height: 400px;
    }

    &-title,
    &-copy {
      //padding-top: 0;
      padding-left: 0 !important; // !important sovrascrive la versione reverse
      padding-right: 0 !important; // !important sovrascrive la versione reverse
    }

    &-row {
      .text {
        padding-top: 0;
      }
    }
  }

  @media #{$sm-max} {
    &-copy {
      padding-top: 30px;
    }
  }

  @media #{$xs-max} {
    &-title {
      padding-right: 0;
      padding-left: 0 !important; // Sovrascrive .row-reverse
    }

    &-copy {
      padding-left: 0;
      padding-right: 0;
    }

    img {
      margin-bottom: 30px;
      margin-top: 20px;
    }

    &-square {
      display: none;
    }
  }

  &.mag {
    .editorial-row .text {
      padding-top: 0%;
    }

    .editorial-copy {
      padding-top: 1%;

      @media #{$xs-max} {
        padding-top: 5%;
      }
    }

    .video {
      .fa-play-circle {
        font-size: 20px;
        bottom: -2px;
        position: relative;
      }
    }
  }
}

#zoom-video-modal {
  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
// .editoriale
.panaria-usa .editorial-square {
  background-color: $sand;
  display: none;
}
// allineamento immagini
.editorial-row {
  .editorial__image {
    text-align: right;
  }
}

.editorial-row-reverse {
  .editorial__image {
    text-align: left;
  }
}
/* -------------------------------------------------- 
  Correlati
-------------------------------------------------- */
.related {
  @extend .section;

  header .title {
    @extend .title-sans;
    @extend .title-lg;
    @extend .spacer;
  }

  .thumb-component {
    .title {
      @extend .title-sans;
    }

    p {
      margin-bottom: 0;
    }
  }

  .slick-slide {
    margin-left: 20px;
    margin-right: 20px;
  }

  .cta {
    margin: 40px 0 0;
    a {
      color: var(--www-panaria-it-black, #000);
      text-align: center;

      /* www.panaria.it/Source Sans Pro/Bold 18 underline */
      font-family: "Source Sans Pro";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 30.6px; /* 170% */
      letter-spacing: 1px;
      text-decoration-line: underline;
    }
  }
}
/* -------------------------------------------------- 
  Slider
-------------------------------------------------- */
.slick {
  // Frecce
  &-next,
  &-prev {
    height: 40px;
  }

  &-next {
    @media (max-width: 767px) {
      right: 0;
    }
  }

  &-prev {
    @media (max-width: 767px) {
      left: 0;
    }
  }

  &-next:before,
  &-prev:before {
    font-family: FontAwesome;
    font-size: 40px;
  }

  &-next:before {
    content: "\f105";
    color: #f26122;
  }

  &-prev:before {
    content: "\f104";
    color: #f26122;
  }

  &-slide {
    &,
    &:focus {
      outline: none;
    }

    img {
      max-width: 100%;
    }
  }
}

.default-slider {
  .slick {
    &-slide {
      overflow: hidden;

      &,
      &:focus {
        outline: none;
      }

      iframe {
        height: 550px;
        width: 100%;
        aspect-ratio: 3 / 2;

        @media #{$sm-max} {
          height: 400px;
        }
      }

      img {
        height: 550px;

        @media #{$sm-max} {
          height: 400px;
          margin: 0 auto;
          object-fit: cover;
        }
      }
    }

    &-prev {
      z-index: 1;
    }
    // Punti gallery interna
    &-dots {
      li {
        button {
          background: white;
          border: 2px solid $orange;
          border-radius: 15px;
          width: 14px;
          height: 14px;
          transition: all 0.3s ease-in-out;

          &:before {
            content: none;
          }
        }

        &.slick-active button {
          border: 2px solid white;
          background: transparent;
        }
      }
    }
  }
}

.slider-dark-ui {
  .slick-next:before,
  .slick-prev:before {
    color: $black;
  }
}
/* -------------------------------------------------- 
  Inline select
-------------------------------------------------- */
.inline-select {
  display: inline-block;
  position: relative;

  &.active {
    z-index: 10;
  }

  span,
  li,
  a {
    color: $orange;
  }

  ul {
    @extend .list-reset;
    display: none;
    position: absolute;
    top: 30px;
    left: 0;
    min-width: 150px;
  }

  li {
    background: $black;
  }

  &:hover {
    cursor: pointer;
  }
}
/* -------------------------------------------------- 
  Share row (magazine)
-------------------------------------------------- */
.social-share {
  .share-row {
    margin: 0px 0 60px;
  }
}
.share-row {
  margin-top: $space / 2;

  .title {
    @extend .title-sans;
    margin-bottom: $space / 6;
    text-transform: uppercase;
    color: $orange;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .btn {
      min-width: 200px;
    }
  }

  @media (max-width: 767px) {
    .btn {
      margin-bottom: $space / 10;
      display: block;
      width: 77%;
      margin-right: auto;
      margin-left: auto;
    }
  }
}
/* -------------------------------------------------- 
  Default header
-------------------------------------------------- */
.default-header {
  text-align: center;
  margin-top: $space * 0.5;

  .title {
    margin: 0 auto;
    margin-bottom: $space / 3;
    display: inline-block;
    padding-bottom: 20px;
    margin-bottom: 30px;

    &-quotes path {
      fill: $orange;
    }
  }

  .abstract {
    font-weight: 500;
  }

  @media #{$xs-max} {
    .title {
      margin-top: 0;
    }
  }
}
/* -------------------------------------------------- 
  Default back
-------------------------------------------------- */
.default-back {
  padding-top: 20px;
  margin-bottom: -60px;

  a {
    text-transform: uppercase;

    &,
    &:hover {
      color: $black;
    }
  }
}
/* -------------------------------------------------- 
  Modale
-------------------------------------------------- */
.modal-header {
  border-bottom: 0px solid #e5e5e5;
}

.modal-body {
  padding: 0px 15px 15px 15px;
}

#cambio-country .titolo {
  font-size: 14px;
  color: $black;
  margin-bottom: 40px;
  font-weight: 500;
}

#scelta-country {
  text-align: center;
  border-bottom: 1px solid #c1c1c1;
  padding-bottom: 40px;

  a {
    display: inline-block;
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
    color: $black;
    opacity: 0.4;

    &.active {
      opacity: 1;
    }

    &:hover {
      text-decoration: none;
    }

    img {
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 767px) {
  #scelta-country {
    padding-bottom: 10px;

    a {
      margin-bottom: 20px;

      span {
        display: block;
      }
    }
  }
}

#scelta-lingua {
  ul {
    margin: 0;
    padding: 40px 0;
    text-align: center;
    display: none;

    &.active {
      display: block;
    }

    li {
      list-style-type: none;
      display: inline-block;

      &.active a {
        color: #f26122;
      }

      a {
        font-size: 20px;
        text-transform: uppercase;
        color: $black;
        margin: 0 10px;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #scelta-lingua {
    ul {
      padding: 20px 0;
    }
  }
}
/* -------------------------------------------------- 
  Custom select
-------------------------------------------------- */
.custom-select {
  overflow: hidden;
  background: url(/img/icone/arrow-orange.png) no-repeat right center;
  background-size: 12px 9px;
  max-width: 220px;

  select {
    width: 100%;
    background: none;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      outline: none;
    }
  }

  option {
    border: 0;
  }
}
/* -------------------------------------------------- 
  Lang change
-------------------------------------------------- */
.lang-change {
  label {
    float: left;
    margin-right: 10px;
    font-weight: normal;
  }

  select {
    color: $orange;
    // text-decoration: underline;
    option {
      color: white;
      padding: 5px;
      text-decoration: none !important;
      background: $black;
      border: 0 !important;
      outline: 0;
    }
  }

  &__group {
    clear: both;
  }
}
/* -------------------------------------------------- 
  Full gallery
-------------------------------------------------- */
.full-gallery {
  @extend .section;
  overflow: hidden;
  background-color: $sand;

  .title {
    @extend .title-sans;
    @extend .title-lg;
    @extend .spacer-sm;
    text-align: center;
  }

  figure {
    // display:flex;
  }

  img {
    @extend .shadow;
    @extend .spacer-sm;
    height: 550px;
  }

  .slick {
    &-slide {
      margin-right: 40px;
    }

    &-next:before,
    &-prev:before {
      color: $black;
    }
  }
  // Versione scura
  &--dark {
    background-color: $black;
    color: white;
  }

  @media #{$xs-max} {
    img {
      max-width: 100%;
      height: 280px; // 400px;
      object-fit: cover;
      object-position: center;
      margin: 0 auto 30px;
    }

    .slick {
      &-slide {
        margin-right: 0;
      }
    }
  }
}
/* -------------------------------------------------- 
  Fascia iscrizione newsletter
-------------------------------------------------- */
.section-newsletter {
  @extend .section;
  background-color: $pink;

  input[type="text"] {
    @extend .spacer-mobile;
    background: transparent;
    border: 0;
    border-bottom: 2px solid $black;
    border-radius: 0;
    box-shadow: none;
  }

  @media #{$xs-max} {
    &,
    input[type="text"] {
      text-align: center;
    }

    button {
      display: block;
      width: 100%;
    }
  }
}
/* -------------------------------------------------- 
  Back to top
-------------------------------------------------- */
.back-to-top {
  $size: 40px;
  width: $size;
  height: $size;
  color: white;
  background: $sanddark;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 100;
  text-align: center;
  padding-top: 7px;
  font-size: 18px;
  opacity: 0;
  transition: opacity 0.3s;

  &:link,
  &:visited,
  &:hover,
  &:active {
    color: white;
  }

  &.visible {
    opacity: 0.6;

    &:hover {
      color: white;
      opacity: 1;
    }
  }
}
/* -------------------------------------------------- 
  Default-body
-------------------------------------------------- */
.default-body {
  padding-bottom: $space;
}
/* -------------------------------------------------- 
  Fascia certificazioni
-------------------------------------------------- */
.section-quality {
  @extend .section;
  background-color: $sand;
  text-align: center;

  .title {
    @extend .title-sans;
    // @extend .title-lg;
    @extend .spacer;
    text-transform: uppercase;
  }

  article p {
    font-size: 14px;
  }

  @media #{$xs-max} {
    article p {
      font-size: 11px;
    }
  }
}
/* -------------------------------------------------- 
  Add to downloads
-------------------------------------------------- */
.add-to-downloads {
    .download-add {
        /*margin-right: 10px;*/
        color: #D14328;

        @media #{$xs-max} {
            padding: 14px 14px;
        }

        &:visited {
            color: #D14328;
        }

        &:after {
            background-color: transparent;
            border: 1px solid;
        }
    }

    .download-view {
        @media #{$xs-max} {
            display: block;
            margin: 10px 0;
            text-decoration: underline !important;

            &:after {
                display: none;
            }
        }
    }
}
/* -------------------------------------------------- 
  Zoom images
-------------------------------------------------- */
.zoom-image {
  &-wrapper {
    position: relative;
    display: block;

    .modal-gallery-btn {
      right: 0;
    }

    .zoom-link {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      cursor: pointer;
      z-index: 100;
    }
  }
}

.logo-think-zero {
  max-width: 100px !important;
  position: absolute !important;
  top: 10px;
  right: 10px;
  z-index: 1000 !important;
}

.hero-text {
  a {
    &:after {
      display: none;
    }
  }

  .logo-protect {
    max-width: 150px;
    margin-right: 20px;
    transition: all ease-in-out 0.3s;

    &:hover,
    &:focus {
      opacity: 0.7;
      transition: all ease-in-out 0.3s;
    }
  }

  .logo-think-zero-big {
    max-width: 160px;
    transition: all ease-in-out 0.3s;
    margin-left: 20px;

    &:hover,
    &:focus {
      opacity: 0.7;
      transition: all ease-in-out 0.3s;
    }
  }
}

.embed-responsive embed-responsive-16by9 {
  @media #{$xs-max} {
  }
}

.fancybox-slide {
  img {
    max-width: 90vw;
    max-height: 90vh;
  }
}

.fancybox-slide > * {
  padding: 0;
  margin: 0;
}

@media #{$md-max} {
  .fancybox-slide {
    img {
      width: 100%;
      height: 500px;
    }
  }
}

@media #{$sm-max} {
  .fancybox-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: auto;
    }
  }
  .fancybox-slide::before {
    //height: auto;
  }
}
