﻿/* -------------------------------------------------- 
  Think Zero
-------------------------------------------------- */
.think-zero {
  .hero {
    .slick-slide {
      background-position: center;
      padding-bottom: 40%;

      @media #{$sm-max} {
        //height: 0 !important;
        padding-bottom: 0;
      }

      img {
        height: 100%;
      }
    }

    .slick-track {
      width: 100% !important;

      .slick-slide {
        width: 100% !important;
      }
    }
  }

  .editorial-row .text {
    padding: 0 4%;

    .editorial-title {
      margin-bottom: 30px;

      @media #{$sm-max} {
        margin-bottom: 30px;
      }
    }

    .cta-link {
      a {
        font-weight: 600;
        font-family: "Crimson Text";
        font-size: 18px;
      }
    }

    p {
      color: var(--www-panaria-it-mine-shaft, #333);
      /* www.panaria.it/Crimson Text/Regular 18 */
      font-family: "Crimson Text";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30.6px; /* 170% */
      letter-spacing: 1px;

      a {
        color: $orange;
        text-decoration: none;

        &:focus,
        &:hover {
          text-decoration: underline;

          &:after {
            width: 100%;
          }
        }
      }
    }
  }

  .editorial-row {
    &:first-child {
      margin-bottom: 60px;
    }
  }

  .section--img-text .play-video {
    height: 625px;

    @media #{$sm-max} {
      height: 250px;
    }

    .circle {
      width: 120px;

      .icon--play-progress-background {
        fill: transparent;
        width: 120px;
      }

      .icon--play {
        fill: transparent;
        stroke: #fff;
      }

      &:hover {
        .icon--play-progress-background {
          fill: #fff;
        }

        .icon--play {
          fill: #000;
          stroke: #000;
        }
      }

      .label-video {
        color: var(--www-panaria-it-nero, #fff);
        text-align: center;
        /* www.panaria.it/Source Sans Pro/Regular 14 */
        font-family: "Source Sans Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 15.4px; /* 110% */
        letter-spacing: 1px;
        position: absolute;
        bottom: -30px;
        text-transform: uppercase;
      }
    }
  }

  .related {
    .slick-slide {
      margin-left: 40px;
      margin-right: 40px;
    }

    .title {
      color: var(--www-panaria-it-mine-shaft, #333);
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 38.4px;
      letter-spacing: 1px;
      margin: 0 0 30px;
      text-align: center;
    }

    .cta {
      a {
        color: $orange;
        text-decoration: none;
        font-family: "Crimson Text";
        font-size: 18px;

        &:focus,
        &:hover {
          text-decoration: underline;

          &:after {
            width: 100%;
          }
        }
      }
    }
  }
}

.section--text {
  padding: 50px 0 100px;

  @media #{$sm-max} {
    padding: 50px 0;
  }

  .title {
    font-family: "Source Sans Pro";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 38.4px; /* 120% */
    letter-spacing: 1px;
    margin: 0 0 20px;
  }

  .cta-link {
    a {
      font-weight: 600;
      font-family: "Crimson Text";
      font-size: 18px;
    }
  }

  p {
    color: var(--www-panaria-it-mine-shaft, #333);
    /* www.panaria.it/Crimson Text/Regular 18 */
    font-family: "Crimson Text";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30.6px; /* 170% */
    letter-spacing: 1px;

    a {
      color: $orange;
      text-decoration: none;

      &:focus,
      &:hover {
        text-decoration: underline;

        &:after {
          width: 100%;
        }
      }
    }
  }

  .cta {
    margin: 40px 0 0;
    text-align: center;
  }
}

.section--img-text {
  padding: 100px 0;

  @media #{$sm-max} {
    padding: 50px 0;
  }

  .row {
    display: flex;

    @media #{$sm-max} {
      flex-direction: column;
    }
  }

  .col-video {
    order: 0;

    @media #{$sm-max} {
      order: 1;
    }
  }

  .col-txt {
    order: 1;

    @media #{$sm-max} {
      order: 0;
    }
  }

  .title {
    font-family: "Source Sans Pro";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 38.4px; /* 120% */
    letter-spacing: 1px;
    margin: 0 30px;

    @media #{$sm-max} {
      margin: 0;
    }
  }

  .desc {
    margin: 30px;
    color: var(--www-panaria-it-mine-shaft, #333);
    /* www.panaria.it/Crimson Text/Regular 18 */
    font-family: "Crimson Text";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30.6px; /* 170% */
    letter-spacing: 1px;

    @media #{$sm-max} {
      margin: 20px 0 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .play-video {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    /*height: 584px;*/
    height: 390px;
    position: relative;
    z-index: 3;
    max-width: 1460px;
    margin: auto;
    margin-bottom: 40px;

    @media #{$sm-max} {
      margin-top: 40px;
      margin-bottom: 0;
    }

    &:after {
      content: "";
      background: #878887;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.1;
    }

    .circle {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      /*width: 196px;
                height: 196px;*/
      height: 100px;
      width: 100px;
      transform: translateX(-50%) translateY(-50%);
      cursor: pointer;
      z-index: 5;
    }

    .icon--play-progress-background {
      display: block;
      position: absolute;
      width: 100px;
      height: 100px;
      top: 0;
      left: 0;
      stroke: #fff;
      fill: #fff;
      opacity: 1;
    }

    .icon--play {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 20px;
      margin-left: -9px;
      margin-top: -9px;
      fill: #000;
    }

    @media (max-width: 1199px) {
      height: 365px;
    }

    @media (max-width: 575.98px) {
      height: 144px;
    }
  }
}

.section--features {
  background: #f8f8f8;
  padding: 50px 0;
  .slider {
    article {
      text-align: center;
      padding: 0 50px;
      cursor: pointer;
      img {
        max-width: 80px;
        max-height: 80px;
        position: relative;
        margin: 0 auto 30px;
      }
      .title {
        font-family: "Source Sans Pro";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 19.8px; /* 110% */
        letter-spacing: 1px;
      }
      .text {
        font-family: "Crimson Text";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 30.6px; /* 170% */
        letter-spacing: 1px;
      }
    }
  }
}

.modal-icon {
  min-width: 500px;
  //min-height: 500px;
  max-width: 500px;
  padding: 40px;
  text-align: center;

  @media #{$sm-max} {
    min-width: 100%;
    max-width: 100%;
  }

  .title {
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 32px;
    font-weight: bold;
    padding: 0 0 20px;
  }
}
