﻿
/* -------------------------------------------------- 
  Listing
-------------------------------------------------- */
.listing {

  // Ricerca
  &-search {
    padding: 60px 0 90px;
    position: relative;
    z-index: 2;

    .title {
      @extend .spacer-sm;
      text-align: center;
    }
  }


  // Risultati
  &-results {

    // Corregge errore di layout nella griglia
    // su iOS
    & > .container-fluid > .row {
      &:before, &:after {
        content: none;
      }
    }

    .thumb-component {
      text-align: center;
      margin-bottom: $space / 2;
    }
  }


  // iPad landscape
  @media only screen and (min-device-width: 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    
  }



    @media #{$xs-max} {
      &-search {
        padding: $space * .25 0;
      }
    }
  }


  /* -------------------------------------------------- 
  Listing Progetti
-------------------------------------------------- */
  .listing-progetti {
      .project-grid{
          .thumb-component{
              min-height: 450px;
              @media (max-width: 767px){
                    min-height: initial;
              }
          }
      }
  }


/* -------------------------------------------------- 
  Listing news
-------------------------------------------------- */
.magazine-listing {
    .news-grid {
        flex-wrap: wrap;
        display: flex;

        .thumb-component {
            margin-bottom: 100px;

            @media (max-width: 767px) {
                margin-bottom: 40px; 
            }
        }
    }
}

/* -------------------------------------------------- 
  Listing prods
-------------------------------------------------- */
.listing-prods {
    .logo-think-zero {
        height: auto !important;
    }
}