﻿/* -------------------------------------------------- 
  Global
-------------------------------------------------- */
.error-style {
  border: 1px solid #be4018 !important;
}

.error {
  + .select2-container {
    border: 1px solid #be4018 !important;

    + {
      .msg-error {
        display: block;
      }
    }
  }
}

.input-group {
  .btn {
    min-width: initial;
  }
}

.select2 {
  max-width: 100%;
  padding: 10px 16px;
  background: #fff;

  .select2-selection {
    border-radius: 0 !important;
    border: none !important;
    font-size: 16px;
  }

  .select2-selection__rendered {
    color: #555;
  }

  .select2-selection__arrow {
    top: 10px;
  }
}

.msg-error {
  display: none;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  color: #b92442;
  margin-top: 10px;
}

.form-control {
  border-radius: 0;
  transition: border 0.2s;
  font-size: 16px;

  &:focus {
    box-shadow: none;
  }

  &.error {
    @extend .error-style;
    + .msg-error {
      display: block;
    }
  }
}

form {
  .checkbox {
    margin-bottom: 0;

    input {
      position: relative;
      background: #c9c0b6;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 16px;
      height: 16px;
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      font-size: 16px;
      margin: 6px 8px 0 0;
      cursor: pointer;

      &.error {
        border: 1px solid #be4018 !important;
      }

      &:focus {
        outline: none;
        border: 0;
      }

      &:checked {
        &:after {
          content: "x";
          position: absolute;
          color: #232120;
          top: 50%;
          left: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
          font-weight: 100;
          line-height: 1;
        }
      }
    }

    &.error {
      //@extend .error-style;
      padding-bottom: 6px;

      input {
        background-color: #b92441;
      }
    }
  }
}

.form_message_ok {
  color: #53c400;
  font-weight: 600;
}

/* -------------------------------------------------- 
  Form
-------------------------------------------------- */
.select-style {
  border: 1px solid #ccc;
  width: 120px;
  border-radius: 3px;
  overflow: hidden;
  background: #fafafa url("img/icon-select.png") no-repeat 90% 50%;
}

.select-style select {
  padding: 5px 8px;
  width: 130%;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
}

.select-style select:focus {
  outline: none;
}

/* -------------------------------------------------- 
  Contacts form
-------------------------------------------------- */
.contacts__form {
  @extend .section;
  background-color: $sand;
  text-align: left;

  form {
    width: 100%;

    [class*="col-"] {
      margin-bottom: 30px;
    }
  }

  .form-control {
    border: 0;
    box-shadow: none;
    transition: box-shadow 0.2s ease-out;

    &:focus {
      box-shadow: 10px 15px 15px -10px rgba(0, 0, 0, 0.1);
    }
  }

  button[type="submit"] {
    margin-top: 20px;
  }

  #form_contatti {
    @media (max-width: 767px) {
      width: 97%;
      margin: auto;
    }
  }
}
