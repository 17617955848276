﻿/* -------------------------------------------------- 
  Buttons
-------------------------------------------------- */
$transition-time: .3s;



.btn {
  @extend .sans-serif;
  border: 0;
  font-size: 15px;
  min-width: 260px;
  text-transform: uppercase;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  color: white;
  background: transparent;
  z-index: 0;
  transition: color $transition-time ease-in-out;
  outline: 0!important;
  cursor: pointer !important;

  &:before, &:after {
    content: '';
    position: absolute;
    height: 100%;
    top:0;
    left: 0;
  }

  &:before {
    background: $sand;
    opacity: 1;
    width: 0;
    z-index:-1;
    transition: width $transition-time ease-in;
  }

  &:after {
    background: $orange;
    z-index:-2;    
    width: 100%;
  }

  &:hover {
    color: $orange; 
    background-color: transparent;
    &:before {
      width: 100%;
    }
  }
  &:visited {
    color: white;
    &:hover {
      color: $orange;
    }
  }


  // Versione bianca
  &-white {
    color: $orange;
    &:before {
      background: $sand;
      width: 0%;
    }
    &:after {
      background: white;
      width: 100%;
    } 
    &:visited {
      color: $orange;
      &:hover {
        color: $orange;
      }
    }
  } 

  // Versione color sabbia
  &-sand {
    color: $black;
    &:before {
      background: $sanddark;
      width: 0%;
    }
    &:after {
      background: $sand;
      width: 100%;
    } 
  } 

  // Versione color sabbia scura
  &-sanddark {
    color: $black;
    &:before {
      background: $sand;
      width: 0%;
    }
    &:after {
      background: $sanddark;
      width: 100%;
    } 
  } 

  // Versione disattivata
  &-inactive {
    color: $sanddark;
    &:before {
      background: $sand;
      width: 0%;
    }
    &:after {
      background: $sand;
      width: 100%;
    } 
  } 

  // Versione Ghost
  &-ghost {
    color: $black;
    padding-top: 18px;
    padding-bottom: 18px;
    border: 2px solid $black;
    &:before {
      background: $sand;
      width: 0%;
    }
    &:after {
      content: none;
    } 
    &:hover {
      color: $black;
      &:before {

      }
    }
  } 

}