﻿/* -------------------------------------------------- 
  Contenintore
-------------------------------------------------- */



.downloads-wrapper {
    padding: 50px 0;
    height: 100%;

    @media (max-width: 767px) {
        padding: 50px 20px;
    }

    .row-flex {
        display: flex;

        @media (max-width: 767px) {
            flex-wrap: wrap;
        }
    }

    @media (min-width: 768px) and (max-width:1199px){
        margin-right:20px;
    }
}

/* -------------------------------------------------- 
  My collection
-------------------------------------------------- */
.download-row {
    //padding: ($space / 1.5) 0 ($space/1.5);


    @media (max-width: 1024px) {
        padding-right: 20px;
        padding-left: 20px;
    }

    &:first-child {

        [class*="title"] {
            margin-top: 0;
        }
    }

    header {
        overflow: hidden;

        &.my-wishlist {
            padding: 50px 0 0;

            h3 {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }


    [class*="title"] {
        margin-bottom: $space/3;
        margin-top: $space/3;
        text-align: left;
        float: left;



        .fa {
            color: $orange;
            font-size: 30px;
        }
    }

    .download-showall {
        float: right;
        text-transform: uppercase;
        text-decoration: underline;
        font-size: 12px;
        color: $black;
        margin-top: 10px;

        span {
            display: none;

            &:first-child {
                display: block;
            }
        }
    }

    .download-list {
        //overflow: hidden;

        &.showall {
            height: auto !important; // !important per sovrascrivere lo stile dato via JS
        }
    }

    article {
        overflow: hidden;
        text-align: left;

        & > div {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            border-top: 1px solid $black;
            padding-top: 15px;
            padding-bottom: 15px;
        }

        span {
            flex: 1 0 33%;
            font-weight: 600;
            &.name {
                font-size: 18px;
            }
            &.filesize {
                font-size: 12px;
                font-weight: 400;
            }
        }

        &:last-child > div, &:nth-last-child(2) > div {
            border-bottom: 1px solid $black;
        }

        .cta {
            text-transform: uppercase;
            text-align: left;
            padding: 10px 0 0;

            a {
                color: $black;
                margin-left: 0;
                margin-right: 10px;
                letter-spacing: -0.2px;
                font-weight: 400;
                font-size: 16px;

                @media (max-width: 768px) {
                    display: inline-block;
                    white-space: nowrap;
                }

                img {
                    position: relative;
                    right: 2px;
                    bottom: 1px;
                }

                &.browse-link {
                }
            }
        }

        @media (max-width: 991px) {
            &:nth-last-child(2) > div {
                border-bottom: 0;
            }
        }
    }
    // article
    &--orange {
        article {
            & > div {
                border-top-color: white;
            }

            .cta a {
                color: white;
            }

            .cta {
                svg {
                    path {
                        fill: #ffffff !important;
                    }
                }
            }

            &:last-child > div, &:nth-last-child(2) > div {
                border-bottom-color: white;
            }
        }
    }


    &--sand {
        background-color: $sand;

        article {
            & > div {
                border-top-color: $black;
            }

            .cta a {
                color: $black;
            }
        }
    }

    &--pink {
        background-color: $pink;

        &, .title {
            color: $black;
        }

        article {
            & > div {
                border-top-color: $black;
            }

            .cta a {
                color: $black;
            }
        }
    }
}
// .download-row


/* -------------------------------------------------- 
  My collection
-------------------------------------------------- */
.download-mycollection {
    background: $sand;
    padding: 0 0 50px;

    @media (max-width: 767px) {
        padding: 0 20px 50px;
    }

    [class*="title"] {
        margin-bottom: $space/3;
        text-align: left;
        margin-top: $space/3;

        &:first-child {
            margin-top: 0;
        }

        .fa {
            color: $orange;
            font-size: 30px;
        }
    }
}

.downloads-index {
    height: 100%;

    @media (max-width: 767px) {
        display: none;
    }

    ul {
        margin: 0;
        padding: 0 20px;
        list-style: none;
        position: sticky;
        top: 100px;

        li {
            border-bottom: 1px solid #ccc;

            &:last-child {
                border-bottom: 0;
            }
        }
    }
}