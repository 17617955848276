﻿$transitionProps: .3s ease-in-out;
$transitionDelay: .2s;
$borderWhite: 1px solid rgba(0,0,0,.2);
$borderBlack: 1px solid rgba(255,255,255,.2);
$menuZIndex: 100;
$logoZIndex: 1;
$dropdpwnLineHeight: 2.5;
$mobileMenuMax: 'only screen and (max-width : 1199px)';


/* -------------------------------------------------- 
  Menu
-------------------------------------------------- */
.main-nav {
    $h: 90px;
    position: fixed;
    z-index: 100;
    width: 100%;
    height: $h;
    line-height: $h;
    border-bottom: $borderBlack;
    transition: background-color $transitionProps, border-color $transitionProps;
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;

    .mobile-toggler {
        display: none;
    }

    .relative {
        width: 100%;
    }

    a {
        font-size: 13px;
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        transition: color $transitionProps,;
    }

    .nav-selector {
        font-size: 14px;
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        transition: color $transitionProps;
        padding: 0px 0px 0px 20px;

        .custom-select {
            float: right;
            margin-left: 10px;

            select {
                line-height: 32px;

                option {
                    background: white;
                    color: black;
                }
            }
        }
    }


    &__hamburger {
        .fa-times {
            display: none;
        }
    }
    /* -------------------------------------------------- 
    Gruppi di link
  -------------------------------------------------- */
    &__group {
        margin: 0;
        padding: 0;
        position: relative;
        z-index: $logoZIndex + 1;

        li {
            display: block;
            float: left;
            padding: 0;
            min-width: 120px;
            text-align: center;

            &.active a {
                color: $orange;

                &:after {
                    height: 100%;
                }
            }
        }

        a {
            display: block;
            font-weight: 500;
            padding: 0 30px;
            position: relative;
            transition: color $transitionProps;

            &.paese-lingua {
                /*color:#C12B2B;*/
            }

            &:after {
                content: '';
                position: absolute;
                height: 0;
                width: 100%;
                top: 0;
                left: 0;
                z-index: -1;
                background: rgba($sand, .95);
                will-change: background;
                transition: height $transitionProps;
            }

            &:hover:after {
                height: calc(100% - 1px);
                // background: rgba(255, 255, 255, .4);
            }
        }
    }
    // .main-nav__group
    .nav-left {
        float: left;

        li {
            transition: border-color $transitionProps;
            border-right: $borderBlack;
        }

        a {
            display: block;
            font-weight: 600;
            // Freccine su/giù per mobile
            .fa-angle-down, .fa-angle-up {
                display: none;
            }
        }
    }

    .nav-right {
        float: right;

        img {
            transform: translateY(-4px);
            margin-right: 5px;
        }

        svg {
            transform: translateY(3px);
            max-width: 20px;
            max-height: 20px;

            path, rect {
                transition: fill $transitionProps;
                fill: white;
            }

            #phone, #square {
                fill: transparent;
            }
        }
    }


    &:after { // Fondo sfumato
        @include square(100%);
        content: '';
        position: absolute;
        background-image: linear-gradient(to bottom, rgba(0,0,0,.5), transparent);
        z-index: -1;
        top: 0;
        left: 0;
    }
    /* -------------------------------------------------- 
    Logo
  -------------------------------------------------- */
    &.menu-always-fixed {
        .panaria-logo {
            .made-in-usa {
                &.white {
                    .red {
                        fill: #C12B2B;
                    }

                    .black {
                        fill: $black;
                    }

                    .blue {
                        fill: #272760;
                    }
                }
            }
        }
    }

    .panaria-logo {
        position: absolute;
        width: 100%;
        display: block;
        text-align: center;
        height: $h;

        a {
            padding: 0;
            position: relative;
        }

        svg {
            width: 160px;
            margin-top: 2px;
            transform: width $transitionProps;

            .shadow {
                display: none;
            }

            rect, polygon, path {
                transition: fill $transitionProps;
            }

            &.white {
                .glare-fill, .main-shape, .glare-stroke {
                    fill: white;
                }
            }
        }
        // gestione bandiera americana
        .made-in-usa {
            position: absolute;
            top: -40px;
            right: -72px;
            width: 60px;

            @media (max-width: 767px) {
                top: -51px;
            }

            .red {
                fill: #C12B2B;
            }

            .white {
                fill: #FFF;
            }

            .blue {
                fill: #272760;
            }

            .black {
                fill: $black;
            }

            &.white {
                .red,
                .white,
                .blue,
                .black {
                    fill: white;
                }
            }
        }
    }
    // Logo
    /* -------------------------------------------------- 
    Mega dropdown
  -------------------------------------------------- */
    .dropdown {
        width: 100%;
        position: absolute;
        background: rgba($sand, .99);
        padding: $space/2;
        top: $h;
        overflow: hidden;
        display: none;
        /* Gruppi all'interno del lenzuolone */
        &-group {
            float: left;
            width: 14%;
            max-width: 240px;
            margin-right: 30px;

            ul {
                @extend .list-reset;
                line-height: $dropdpwnLineHeight;
            }

            li {
                line-height: 1.5;
                margin-bottom: 15px;
            }

            a {
                color: $black;
                font-size: 16px;
                font-weight: 300;
                text-transform: none;
            }
            // Abstract
            &__abstract {
                font-size: 14px;
                line-height: 1.7;
            }
            // Ricerca prodotti
            &-search {
                line-height: 2.5;

                input {
                    height: 40px;
                    border: 0;
                    padding-left: 5px;
                    padding-right: 5px;

                    &:focus {
                        outline: none;
                    }
                }
            }
            // Cambio lingua
            &.lang-change {
                line-height: $dropdpwnLineHeight;
                border-left: 1px solid rgba(0,0,0,.2);
                padding-left: 10px;
                display: none;

                option {
                    background: $sand;
                    color: $black;
                }
            }
            // .dropdown-group.lang-change
            @media #{$md-max} {
                .dropdown-title {
                    font-size: 14px;
                    min-height: 50px;
                }

                ul {
                    line-height: 1.2;
                }

                li {
                    margin-bottom: 14px;
                }

                a {
                    font-size: 12px;
                }

                &.lang-change, &-search {
                    display: block;
                    clear: both;
                }

                &.lang-change {
                    padding-left: 0;
                    border-left: 0;
                    border-top: 1px solid rgba(0,0,0,.2);
                    padding-top: 30px;
                    margin-top: 20px;
                    font-size: 12px;
                }
            }

            @media #{$mobileMenuMax} {
                &.lang-change {
                    border: 0;
                    border-top: 1px solid rgba(0,0,0,.2);
                    float: none;
                    width: 100%;
                    max-width: initial;
                    overflow: hidden;
                    clear: both;
                    padding-top: 20px;
                    padding-bottom: 10px;

                    .dropdown-title {
                        min-height: initial;
                    }
                }
            }
        }
        // .dropdown-group
        &.prodotti .dropdown-group {
            width: 9%;
        }

        &-title {
            font-weight: 700;
            min-height: 70px;

            &[data-mobile-href]:hover {
                cursor: pointer;
            }

            @media #{$mobileMenuMax} {
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
    // .dropdown
    .panaria-usa .dropdown {
        width: 9%;
    }
    /* -------------------------------------------------- 
    Cerca
  -------------------------------------------------- */
    .modal-link {
        @media #{$sm-max} {
            display: none;
        }
    }

    .main-search {
        padding: 0 15px;

        &.search-mobile {
            display: none;

            @media #{$sm-max} {
                display: block;
            }
        }

        form {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                height: 0;
                width: 100%;
                top: 0;
                left: 0;
                z-index: -1;
                background: rgba($sand, .95);
                will-change: background;
                transition: height $transitionProps;
            }

            &:hover:after {
                height: calc(100% - 3px);
                // background: rgba(255, 255, 255, .4);
            }
        }

        a {
            display: inline-block;
            padding: 0;

            &:before, &:after {
                content: none;
            }
        }

        button {
            border: 0;
            background: transparent;
        }

        input {
            color: white;
            font-size: 14px;
            font-weight: 300;
            text-transform: uppercase;
            letter-spacing: 1px;
            opacity: 1;
            transform: translateY(-1px);
            transition: color $transitionProps, width $transitionProps;
            will-change: color, width;
            padding: 0 10px;
            background: none;
            border: 0;
            width: 115px;
            height: 60px;

            &:focus {
                outline: none;
                width: 180px !important; // !important sovrascrive stile in linea dato via JS per la larghezza
            }
            // I vendor prefix devono stare su righe diverse
            // Non usare @extend
            &::-webkit-input-placeholder {
                color: white;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 1px;
                opacity: 1;
                transition: color $transitionProps;
            }

            &::-moz-placeholder {
                color: white;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 1px;
                opacity: 1;
                transition: color $transitionProps;
            }

            &:-ms-input-placeholder {
                color: white;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 1px;
                opacity: 1;
                transition: color $transitionProps;
            }

            &:-moz-placeholder {
                color: white;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 1px;
                opacity: 1;
                transition: color $transitionProps;
            }
        }


        @media #{$mobileMenuMax} {
            input:focus {
                width: 120px !important; // !important sovrascrive versione desktop
            }
        }
    }
    // .main-search
    /* -------------------------------------------------- 
    Menu Agganciato
  -------------------------------------------------- */
    &.main-nav-fixed {
        background: rgba(255,255,255,.95);
        border-bottom: $borderWhite;

        a.paese-lingua {
            color: #f26122;
        }

        a, .nav-selector {
            color: $black;
        }

        &:after {
            content: none;
        }
        // Fondo sfumato
        .nav-left {
            li {
                border-right: $borderWhite;
            }
        }



        .nav-right {
            float: right;

            @media (max-width: 767px) {
                padding-bottom: 90px;
            }

            svg {
                path, rect {
                    fill: $black;
                }
                #phone, #square {
                    fill: transparent;
                    stroke: #000;
                }
            }
        }

        .panaria-logo {
            .glare-fill, .main-shape, .glare-stroke {
                fill: $black !important; // !important sovrascrive classe data via JS
            }
        }

        .main-search {
            input {
                color: $black; // I vendor prefix devono stare su righe diverse
                &::-webkit-input-placeholder {
                    color: $black;
                }

                &::-moz-placeholder {
                    color: $black;
                }

                &:-ms-input-placeholder {
                    color: $black;
                }

                &:-moz-placeholder {
                    color: $black;
                }
            }
        }
    }
    // .main-nav.main-nav-fixed
    /* -------------------------------------------------- 
    Desktop min 
  -------------------------------------------------- */
    @media (min-width: 992px) and (max-width: 1760px) {
        &__group {
            li {
                min-width: 80px;
            }

            a {
                padding: 0 20px;
            }
        }

        .dropdown {
            &__group {
                ul {
                    line-height: 2;
                }
            }
        }
    }
    // @media (min-width: 992px) and (max-width: 1199px)
    /* -------------------------------------------------- 
    Mobile menu
  -------------------------------------------------- */
    @media #{$mobileMenuMax} {
        $h: 45px;
        $menuH: 60px;
        line-height: $h;
        top: -52x;
        height: auto;
        //overflow: scroll;


        .mobile-toggler { // Attivatore menu mobile
            display: block;
            z-index: $menuZIndex + 1;
            position: fixed;
            top: 0;
            right: 0;
            width: 60px;
            line-height: $menuH;

            a {
                display: block;
                font-size: 20px;
                text-align: center;
            }

            .fa-times {
                display: none;
            }
        }
        // Contenitore menu mobile
        .mobile-wrapper {
            display: none;
            position: relative;
        }

        &.mobile-open { // Menù mobile aperto
            background: white;
            height: 100vh;

            &:after {
                content: none;
            }
            // Fondo sfumato
        }

        a {
            color: $black;
        }

        .panaria-logo {
            position: relative;
            // border-bottom: $borderWhite;
            border-bottom: 0;

            &, a {
                height: $menuH;
            }

            svg {
                height: $menuH;
                width: 110px;
            }
        }

        &__group {
            float: none;
            width: 100%;
            overflow: hidden;

            li {
                border-right: 0;
                border-bottom: $borderWhite;
            }
        }


        .nav-left {
            float: none;

            li {
                width: 100%;
                display: block;
                border-right: 0 !important;

                &.main-nav__hamburger {
                    display: none;
                }
            }

            a {
                text-align: left;
                padding: 0 30px;
                font-size: 15px;
                // Freccine su/giù per mobile
                .fa-angle-down {
                    display: inline-block;
                }
            }
        }


        .dropdown {
            padding: 30px 15px 0;
            top: 0;

            &.secondary-open {
                display: block;
                background: rgb(246, 243, 235);
                z-index: 100;
                border-bottom: 1px solid #c5c2bc;
                box-shadow: 0 3px 15px 0 rgba(0,0,0,0.1);
                padding-bottom: 20px;
            }

            &-group {
                width: 50%;
                margin: 0;
                padding: 0 15px;

                ul {
                    //display: none;
                }

                &.lang-change {
                    padding-left: 15px;
                    display: block;

                    .dropdown-title {
                        margin-bottom: 15px;
                    }
                }
            }

            &-title {
                font-size: 14px;
                min-height: 50px;
            }

            &[data-dropdown="hamburger"] {
                display: block;
                position: relative;
                border-bottom: $borderWhite;
            }

            &.lang-change {
                display: none;
            }
        }
        // .dropdown
        .nav-right {
            li {
                border: 0;
                line-height: 60px;
            }

            .nav-selector {
                display: none;
            }
        }
    }
    // .max-width: 991px
    @media (max-width: 1499px) and (min-width: 992px) {
        .nav-selector {
            display: none;
        }

        .dropdown-group.lang-change {
            display: block;
        }
    }
}
    // .main-nav
    /* -------------------------------------------------- 
  Tendina scura sul body
-------------------------------------------------- */
    .menu-page-overlay {
        position: fixed;
        background: rgba($black, .3);
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: $menuZIndex - 1;
        transition: opacity $transitionProps;
        display: none;
        opacity: 0;
        pointer-events: none;

        &.active {
            opacity: 1;
        }
    }
    /* -------------------------------------------------- 
  Menu ancorato direttamente sulla pagina
  (quando non c'è immagine hero)
-------------------------------------------------- */
    .menu-always-fixed {
        @extend .main-nav.main-nav-fixed;
    }
    /* -------------------------------------------------- 
  Menu interno alla pagina
-------------------------------------------------- */
    .inner-menu {
        border-bottom: $borderBlack;
        position: fixed;
        top: 90px;
        z-index: 11;
        width: 100%;
        text-align: center;
        margin-top: 0;
        background-image: linear-gradient(to top, rgba(0,0,0,.1), transparent);
        padding-top: 0;
        max-width: 1920px;

        @media #{$md-max} {
            top: 61px;
        }

        @media #{$sm-max} {
            width: 100%;
            overflow-x: scroll;
        }

        &.main-nav-fixed {
            background: #be4018;
        }

        ul {
            @extend .list-reset;
            @extend .list-inline;
            margin: 0;
            padding: 5px 0;

            @media #{$sm-max} {
                /*width: 680px;*/
                display: flex;
            }
        }

        li {
            margin: 0 15px;

            @media #{$sm-max} {
                margin: 0 10px;
                white-space: nowrap;

                &:last-child {
                    padding-right: 20px;
                }
            }
        }

        a {
            color: white;
            font-size: 16px;
            font-weight: 500;

            &:hover,, &:focus, &:hover:after {
                color: white;
            }

            .logo-protect {
                max-width: 100px;
            }

            .icon-info {
                width: 15px;
                margin-left: 10px;
            }
        }
    }
