﻿/* -------------------------------------------------- 
  STORE LOCATOR 2024
-------------------------------------------------- */
// Hero Store Locator
.section--hero-store-locator {
  position: relative;
  padding: 150px 0 40px;
  background-color: #f5f2eb;

  @media screen and (max-width: 768px) {
    padding: 90px 4px 0;
  }

  &.fix-section-scroll {
    overflow-x: hidden;
  }

  .title {
    position: relative;
    font-size: 60px;
    line-height: 1.2;
    color: #000000;
    font-weight: 500;
    max-width: 100%;
    padding-bottom: 15px;
    display: inline-block;

    @media (max-width: 1024px) {
      font-size: 39px;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 50px;
      height: 10px;
      background-color: #be4018;

      @media (max-width: 991px) {
        &:after {
          display: block !important;
        }
      }
    }

    @media screen and (max-width: 768px) {
      font-size: 40px;
      line-height: 110%;
      text-align: left;
      max-width: 100%;
      display: block;
    }
  }

  .abstract {
    margin-top: 35px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 1.3;
    color: #000000;

    @media screen and (max-width: 768px) {
      margin-bottom: 0;
      margin-top: 20px;
      text-align: left;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }
}

// Form Top Store Locator
.section--form-top-store-locator {
  background-color: #f5f2eb;
  padding: 0 0 100px;

  @media screen and (max-width: 768px) {
    padding: 40px 0;
  }

  .container {
    max-width: calc(100% - 34px);

    @media screen and (max-width: 768px) {
      margin: 0;
      max-width: 100%;
    }
  }

  &.searched {
    padding: 40px 0;
  }

  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 840px;
    position: relative;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .item {
      // width: calc(25% - 8px);

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      &-input {
        width: calc(50% - 15px);

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }

    input[type="text"] {
      background: #fff;
      border: 0;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: rgba(17, 17, 19, 0.6);
      padding: 17px 20px;
      border: 2px solid #fff;
      cursor: text;
      &:focus {
        outline: none;
      }
    }

    .btn--brown {
      border: 2px solid #be4019;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 17px 26px;
      gap: 10px;
      background: #404040;
      color: #fff;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-transform: uppercase;
      color: #ffffff;
      font-family: "Source Sans Pro", sans-serif;
      min-width: initial;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin: 16px 0;
      }

      &:hover {
        border: 2px solid #404040;
      }

      &::before {
        background: #404040;
      }
    }

    .btn--outline {
      border: 2px solid #be4019;
      padding: 17px 16px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-transform: uppercase;
      color: #be4019;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      font-family: "Source Sans Pro", sans-serif;
      min-width: initial;

      &:after {
        background-color: #fff;
      }

      &:before {
        background-color: #be4019;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      svg {
        margin-right: 10px;
        color: #be4019;
        transition: all ease-in-out 0.3s;
      }

      &:hover {
        background: #404040;
        color: #fff;

        svg {
          color: #fff;
          transition: all ease-in-out 0.3s;
        }
      }
    }
  }
}

// Mappa Store Locator
.section--map-store-locator {
  background: #ffffff;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  .container {
    max-width: calc(100% - 34px);
  }

  .wrap-results {
    display: none;
    width: 40%;
    padding: 24px 40px;
    overflow-y: auto;
    height: 863px;

    @media screen and (max-width: 768px) {
      order: 1;
      width: 100%;
      height: auto;
      padding: 24px;
    }

    &.active {
      display: block;
    }

    .introResults {
      .title {
        font-style: normal;
        font-size: 14px;
        line-height: 160%;
        color: #000000;
        text-align: left;
        font-weight: 400 !important;
      }

      .storeFilters {
        padding: 24px 0;

        .wrap {
          .item {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 160%;
            color: #000000;
            text-align: left;

            span {
              font-weight: 700;
            }
          }
        }
      }

      .no-store {
        padding: 40px 0;
        display: none;

        .title {
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 160%;
          color: #000000;
        }

        .abs {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 160%;
          color: #000000;
          text-align: left;
          margin-top: 8px;
        }
      }
    }

    .listStore,
    .listAgent {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 12px;

      li {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 24px;
        gap: 12px;
        border-top: 4px solid transparent;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 1px solid #bbbbbb;
        transition: all ease-in-out 0.3s;
        cursor: pointer;

        @media screen and (max-width: 768px) {
          padding: 12px 12px 24px;
        }

        &:hover {
          border: 4px solid #000;
          transition: all ease-in-out 0.3s;
        }

        &.active {
          border: 4px solid #000;
          transition: all ease-in-out 0.3s;
        }

        .store-header {
          width: 100%;

          .wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .storeName {
              font-style: normal;
              font-weight: 600;
              font-size: 18px;
              line-height: 140%;
              color: #232120;
              text-align: left;
            }

            .storeDistance {
              font-style: normal;
              font-weight: 600;
              font-size: 12px;
              line-height: 140%;
              color: #232120;
            }
          }
        }

        .store-body {
          width: 100%;

          .wrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            // gap: 12px;

            @media screen and (max-width: 1024px) {
              flex-wrap: wrap;
            }

            .item-info {
              width: calc(60% - 10px);
              @media screen and (max-width: 1339px) {
                width: 100%;
                margin-bottom: 10px;
              }
              ul {
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: column;
                gap: 4px;

                li {
                  padding: 0;
                  display: flex;
                  align-items: flex-start;
                  flex-direction: row;
                  text-align: left;
                  border: 0;

                  &:hover {
                    border: 0;
                  }

                  svg {
                    min-width: 16px;
                    min-height: 16px;
                  }

                  span {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 140%;
                    color: #232120;
                  }

                  a {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 140%;
                    text-decoration-line: underline;
                    color: #232120;
                  }

                  &.hours {
                    .today {
                      width: 100%;
                      .wrap-today {
                        cursor: pointer;
                        display: flex;
                        align-items: center;

                        svg {
                          margin-left: 10px;
                        }
                        &.active {
                          svg {
                            transform: rotate(180deg);
                          }
                        }
                      }
                      &.active {
                        width: 100%;
                        .wrap-today {
                          svg {
                            transform: rotate(180deg);
                          }
                        }
                      }
                    }

                    .week {
                      display: none;

                      li {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 140%;
                        color: #232120;
                        justify-content: space-between;
                        &.highlight-today {
                          font-weight: 700;
                        }
                        .giorno {
                          width: 20%;
                        }
                        .orari {
                          width: 80%;
                        }
                      }
                    }

                    .week.active {
                      display: block;
                    }
                  }
                }
              }
            }

            .item-cta {
              display: flex;
              flex-direction: column;
              gap: 8px;
              width: 40%;
              @media screen and (max-width: 1339px) {
                width: 100%;
              }

              .btn--brown {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8px 26px;
                gap: 10px;
                background: #404040;
                color: #fff;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 140%;
                text-transform: uppercase;
                color: #ffffff;
                font-family: "Source Sans Pro", sans-serif;
                min-width: initial;

                &::before {
                  background: #404040;
                }
              }

              .btn--outline {
                border: 2px solid #be4019;
                padding: 8px 16px;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 140%;
                text-transform: uppercase;
                color: #be4019;
                display: flex;
                align-items: center;
                justify-content: center;
                background: transparent;
                font-family: "Source Sans Pro", sans-serif;
                min-width: initial;

                &:hover {
                  background: #404040;
                  color: #fff;
                }

                &:after {
                  background-color: #fff;
                }

                &:before {
                  background-color: #be4019;
                }
              }
            }
          }
        }
      }
    }

    .listAgent {
      padding: 12px 0 0;

      .tagAgent {
        padding: 4px 8px;
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        border: 1px solid #000;
      }
    }

    .moreHelp {
      padding: 24px 0;
      text-align: left;

      @media screen and (max-width: 768px) {
        padding: 48px 0 0;
      }

      .title {
        font-weight: 700;
        font-size: 24px;
      }

      p {
        margin: 10px 0 0;
        font-size: 16px;
        font-weight: 500;
      }

      .cta {
        padding: 12px 0 0;

        .btn--brown {
          display: inline-flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 26px;
          gap: 10px;
          background: #404040;
          color: #fff;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
          text-transform: uppercase;
          color: #ffffff;
          font-family: "Source Sans Pro", sans-serif;
          min-width: initial;

          @media screen and (max-width: 768px) {
            width: 100%;
            margin: 16px 0;
          }

          &::before {
            background: #404040;
          }
        }
      }
    }
  }

  .wrap-map {
    position: relative;
    max-width: 840px;
    margin: 0 auto;
    top: -50px;
    width: 100%;

    @media screen and (max-width: 768px) {
      order: 0;
      width: 100%;
      top: 0;
    }

    #map-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      z-index: 1000;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      &.active {
        pointer-events: auto;
      }
      &:hover,
      &:focus {
        .text-overlay {
          display: flex;
        }
      }
    }

    .text-overlay {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.85);
      color: #fff;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 140%;
      display: none;
    }

    &.loading,
    &.loaded {
      &:hover {
        .text-overlay {
          display: flex;
        }
      }
    }

    &.searched {
      top: 0;
      max-width: 100%;
      width: 60%;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      #map {
        height: 863px;

        @media screen and (max-width: 768px) {
          max-height: 530px;
        }
      }
    }

    .loader {
      width: 100%;
      height: 534px;
      background-color: #ededed;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      top: 0;
    }

    .progress-bar {
      width: 56px;
      height: 4px;
      background-color: #ccc;
      /* Colore di sfondo */
      position: relative;
      overflow: hidden;
    }

    .progress-bar::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #404040;
      /* Colore della barra di avanzamento */
      animation: loading 1.5s infinite;
    }

    @keyframes loading {
      0% {
        left: -100%;
      }

      50% {
        left: 0;
      }

      100% {
        left: 100%;
      }
    }

    #map {
      height: 534px;
    }
  }

  .gm-style-iw-chr,
  .gm-style-iw-tc {
    // display: none;
  }
  .gm-style-iw-d {
    padding: 16px !important;
    overflow: inherit !important;
    min-width: 300px;
  }

  .gm-ui-hover-effect {
    position: absolute !important;
    top: 0;
    right: 0;
  }

  .gm-style-iw-ch {
    position: static;
    padding-top: 30px;
  }

  .gm-style .gm-style-iw-c {
    border: 1px solid #0a2240;
    padding: 0;
    border-radius: 0;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .gm-style-iw-tc {
    display: none;
  }

  .infoWindowContent {
    text-align: left;
    .infoHeader {
      padding: 0 0 8px;
      .wrap {
        display: flex;
        justify-content: space-between;
        .storeName {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 140%;
          color: #232120;
        }
        .storeDistance {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 140%;
          color: #232120;
        }
      }
    }
    .infoBody {
      font-size: 12px;
      .storeHours {
        padding: 12px 0 0;

        .today {
          &.active {
            .iconArrow {
              transform: rotate(180deg);
            }
          }
        }
        .hoursTitle {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 140%;
          color: #232120;
        }
        .iconHours {
          position: relative;
          top: 4px;
          margin-right: 4px;
        }
        svg {
          position: relative;
          top: 7px;
        }

        .wrap-today {
          cursor: pointer;
          &.active {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        .week {
          display: none;
          list-style: none;
          padding: 0;
          margin: 0;

          &.active {
            display: block;
            padding: 10px 0 0;
          }

          li {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            color: #232120;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .giorno {
              width: 12%;
            }
            .orari {
              width: 75%;
            }
          }
        }
      }
      .storePhone {
        padding: 12px 0 0;
        .iconPhone {
          position: relative;
          top: 4px;
          margin-right: 4px;
        }
        .phoneTitle {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 140%;
          color: #232120;
        }
        a {
          text-decoration: underline;
        }
      }
    }
    .infoFooter {
      padding: 24px 0 0;
      .btn--outline {
        border: 2px solid #be4019;
        padding: 8px 16px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        text-transform: uppercase;
        color: #be4019;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        font-family: "Source Sans Pro", sans-serif;
        min-width: initial;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        &:after {
          background-color: #fff;
        }

        &:before {
          background-color: #be4019;
        }

        svg {
          margin-right: 10px;
          color: #be4019;
          transition: all ease-in-out 0.3s;
        }

        &:hover {
          background: #404040;
          color: #fff;

          svg {
            color: #fff;
            transition: all ease-in-out 0.3s;
          }
        }
      }
    }
  }
}

// Form Bottom Store Locator
.section--form-bottom-store-locator {
  background-color: #fff;
  padding: 80px 0 0;
  display: none;

  .container {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 80px;
    max-width: calc(100% - 34px);

    @media screen and (max-width: 768px) {
      margin: 0;
      max-width: 100%;
    }
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 140%;
    text-align: center;
    color: #232120;
    padding: 0 0 32px;

    @media screen and (max-width: 768px) {
      font-size: 28px;
      line-height: 140%;
      padding: 0 0 24px;
    }
  }

  &.searched {
    padding: 40px 0;
    display: block;
  }

  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 840px;
    position: relative;
    margin: 0 auto;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }

    .item {
      // width: calc(33.33% - 8px);

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      &-input {
        width: calc(50% - 16px);

        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }

    input[type="text"] {
      background: transparent;
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: rgba(17, 17, 19, 0.6);
      padding: 17px 16px;
      border: 1px solid #bbbbbb;
      &:focus {
        outline: none;
      }
    }

    .btn--brown {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 17px 26px;
      gap: 10px;
      background: #404040;
      color: #fff;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-transform: uppercase;
      color: #ffffff;
      font-family: "Source Sans Pro", sans-serif;
      min-width: initial;

      &::before {
        background: #404040;
      }

      @media screen and (max-width: 768px) {
        width: 100%;
        margin: 16px 0;
      }
    }

    .btn--outline {
      border: 2px solid #be4019;
      padding: 17px 16px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-transform: uppercase;
      color: #be4019;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      font-family: "Source Sans Pro", sans-serif;
      min-width: initial;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      &:after {
        background-color: #fff;
      }

      &:before {
        background-color: #be4019;
      }

      svg {
        margin-right: 10px;
        color: #be4019;
        transition: all ease-in-out 0.3s;
      }

      &:hover {
        background: #404040;
        color: #fff;

        svg {
          color: #fff;
          transition: all ease-in-out 0.3s;
        }
      }
    }
  }
}

// Province Store Locator
.section--province-store-locator {
  padding: 48px 0;
  text-align: left;

  .container {
    max-width: 100%;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 140%;
    color: #232120;
    padding: 0;
    margin-bottom: -32px;

    @media screen and (max-width: 768px) {
      margin-bottom: 0;
      font-size: 36px;
      line-height: 140%;
    }
  }

  .regione {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    color: #232120;
    margin-top: 64px;

    @media screen and (max-width: 768px) {
      margin-top: 32px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 12px;

    li {
      padding: 0 0 14px;
      .text-link-line {
        text-transform: uppercase;
        font-size: 18px;
        transition: all ease-in-out 0.3s;
        color: #000;
        &:hover {
          font-weight: 600;
          transition: all ease-in-out 0.3s;
          text-decoration: none;
          color: #be4018;
        }
        &::before {
          background: #000;
          width: 18px;
        }

        &::after {
          background: #000;
        }

        &:hover {
          &::before {
            width: 0;
          }

          &::after {
            width: 18px;
          }
        }
      }
    }
  }

  .wrap-provincie {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: calc(25% - 10px);
      @media screen and (max-width: 767px) {
        width: calc(50% - 10px);
      }
    }
  }
}

// Fancy From
.fancy-form {
  border-radius: 12px;
  max-width: 815px;
  padding: 24px 60px;
  background-color: #f5f2eb;

  @media screen and (max-width: 768px) {
    max-width: 90%;
    padding: 24px;
    height: 100%;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: #232120;
    padding: 0 0 24px;
    font-family: "Source Sans Pro", sans-serif;
  }

  .tagAgent {
    padding: 4px 8px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    border: 1px solid #000;
    display: inline-block;
    margin: 0 0 16px;
  }

  .store-info {
    background-color: #ffffff;
    padding: 16px;
    margin: 0 0 16px;
    font-family: "Source Sans Pro", sans-serif;
    display: block;
    flex-direction: column;
    gap: 12px;

    .store-header {
      width: 100%;
      margin: 0 0 16px;

      .wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .storeName {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: #232120;
          text-align: left;
        }

        .storeDistance {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 140%;
          color: #232120;
        }
      }
    }

    .store-body {
      width: 100%;

      ul {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        gap: 4px;

        li {
          padding: 0;
          display: flex;
          align-items: flex-start;
          flex-direction: row;
          text-align: left;
          border: 0;
          gap: 12px;

          &:hover {
            border: 0;
          }

          svg {
            min-width: 16px;
            min-height: 16px;
          }

          span {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: #232120;
          }

          a {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            text-decoration-line: underline;
            color: #232120;
          }

          &.hours {
            .today {
              width: 100%;
              .wrap-today {
                cursor: pointer;
                display: flex;
                align-items: center;

                svg {
                  margin-left: 10px;
                }
                &.active {
                  svg {
                    transform: rotate(180deg);
                  }
                }
              }
              &.active {
                width: 100%;
                .wrap-today {
                  svg {
                    transform: rotate(180deg);
                  }
                }
              }
            }

            .week {
              display: none;

              li {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 140%;
                color: #232120;
                justify-content: space-between;
                &.highlight-today {
                  font-weight: 700;
                }
                .giorno {
                  width: 20%;
                }
                .orari {
                  width: 80%;
                }
              }
            }

            .week.active {
              display: block;
            }
          }
        }
      }
    }

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #232120;
    }

    svg {
      min-width: 16px;
      min-height: 16px;
    }

    .store-name {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      color: #232120;
      padding: 0 0 16px;
    }

    .store-address {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .store-hours {
      display: flex;
      align-items: flex-start;
      gap: 16px;

      svg {
        position: relative;
        top: 7px;
      }

      .wrap-today {
        cursor: pointer;
      }

      .week {
        display: none;
        list-style: none;
        padding: 0;
        margin: 0;

        &.active {
          display: block;
        }

        li {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: #232120;
        }
      }
    }

    .store-phone {
      display: flex;
      align-items: center;
      gap: 16px;

      a {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        text-decoration-line: underline;
        color: #232120;
      }
    }
  }

  label {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
    font-size: 14px;
  }

  .required-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #000000;
    padding: 0 0 24px;
    font-family: "Source Sans Pro", sans-serif;

    @media screen and (max-width: 768px) {
      padding: 16px 0;
    }
  }

  form {
    .errorTxt {
      display: none;
    }

    .msg-error {
      display: none;
      color: #be4018;
      font-size: 12px;
      font-weight: 400;
      margin-top: 5px;
      text-align: left;
      font-style: italic;
    }

    .form-group {
      @media screen and (max-width: 768px) {
        margin-bottom: 0;
      }
    }

    select {
      height: 46px !important;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: #fff url("/img/caret-down.svg") no-repeat right 10px center;
      background-size: 20px;
      border: none;
      line-height: 21px;
      padding-right: 30px; // Adjust padding to make space for the icon
    }

    .form-control {
      border-radius: 0;
      transition: border 0.2s;
      font-size: 16px;
      border: 0;
      padding: 12px 8px;
      height: auto;

      &:focus {
        box-shadow: none;
      }

      &.error {
        border: 1px solid #be4018 !important;
        + .msg-error {
          display: block;
        }
      }
    }

    .checkbox {
      input.error {
        + .msg-error {
          display: block;
        }
      }
      .msg-error {
        margin-left: 20px;
      }
    }

    .select2 {
      max-width: 100%;
      padding: 10px 16px;
      background: #fff;

      .select2-selection {
        border-radius: 0 !important;
        border: none !important;
        font-size: 16px;
      }

      .select2-selection__rendered {
        color: #555;
      }

      .select2-selection__arrow {
        top: 10px;
      }
    }

    .cta {
      display: flex;
      justify-content: center;

      padding: 16px 0 0;

      .btn--brown {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 50px;
        gap: 10px;
        background: #404040;
        color: #fff;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        text-transform: uppercase;
        color: #ffffff;
        font-family: "Source Sans Pro", sans-serif;
        min-width: initial;

        @media (max-width: 991px) {
          width: 100%;
        }

        &::before {
          background: #404040;
        }
      }
    }
  }

  #thank-you-message {
    text-align: center;
    padding: 200px 0;

    @media screen and (max-width: 768px) {
      padding: 20px 0;
    }

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 140%;
      text-align: center;
      color: #232120;
      padding: 0;
    }

    p {
      margin: 0;
    }

    .cta {
      padding: 16px 0 0;
    }

    .btn--brown {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 26px;
      gap: 10px;
      background: #404040;
      color: #fff;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      text-transform: uppercase;
      color: #ffffff;
      font-family: "Source Sans Pro", sans-serif;
      min-width: initial;

      &::before {
        background: #404040;
      }
    }
  }

  .g-recaptcha {
    padding: 20px 0;
  }
}

// Show Map
.showMap {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 26px 0;
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-left: 24px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #404040;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #beb8b1;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #beb8b1;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

// Btn Top
.btn-top {
  justify-content: center;
  padding: 0 24px;
  position: fixed;
  bottom: 20px;
  display: none;
  z-index: 10000;
  width: 100%;

  @media screen and (min-width: 769px) {
    display: none !important;
  }

  &.searched {
    &.visible {
      @media screen and (max-width: 768px) {
        display: flex;
      }
    }
  }

  .btn {
    padding: 12px;
    width: 100%;
  }

  .btn--brown {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 26px;
    gap: 10px;
    background: #404040;
    color: #fff;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 140%;
    text-transform: uppercase;
    color: #ffffff;
    font-family: "Source Sans Pro", sans-serif;
    min-width: initial;

    &::before {
      background: #404040;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 16px 0;
    }
  }
}

input.error,
select.error,
textarea.error {
  border: 1px solid #be4018 !important;
}

.error {
  .msg-error {
    display: block !important;
    margin-left: 0 !important;
    padding: 0;
  }
}

.highlight-today {
  font-weight: bold !important;
}
