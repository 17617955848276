﻿.acceptcookie {
  position: fixed;
  z-index: 300;
  bottom: 0;
  width: 100%;
  background: $orange;
  color: white;
  padding: 5px 15px;
  font-size: 10px;
  text-align: left;
  z-index: 100;
  font-weight: 100;
  left: 0;

  & > .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
    
  }

  .cookie__info {
    color: white;
    text-decoration: underline;
    margin-left: 15px;
    margin-right: 15px;
  }

  .btn { 
    min-width: 120px; 
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: 500;
  }
}