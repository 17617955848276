﻿

/* -------------------------------------------------- 
  Footer
-------------------------------------------------- */
.main-footer {
    background: $black;
    padding: ($space * .75) 0;
    color: white;
    font-size: 12px;
    line-height: 2.4;

    a {
        color: #fff;

        svg {
            position: absolute;
            left: 0;
            top: 2px;
            color: #fff;
            fill: #fff;
        }
    }

    .wrap-lang-change {
        position: relative;

        a {
            padding-left: 20px;
            color: #ff6a00;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .panaria {
        display: block;

        img {
            max-width: 300px;
        }
    }

    .quick-links {
        @extend .list-reset;
        text-transform: uppercase;
        width: 75%;
        float: left;

        li {
            display: inline-block;
            width: 32%;
        }
    }

    .lang-change {
        width: 25%;
        float: right;

        @media(min-width:768px) and (max-width:1199px){
            margin-top:40px;
        }

        option {
            text-decoration: none;
        }
    }

    .footer-bottom {
        border-top: 1px solid #535455;
        padding-top: 40px;
        margin-top: $space;
    }


    .socials {
        color: white;

        a {
            $size: 37px;
            @include square($size);
            font-size: 18px;
            display: inline-block;
            border: 2px solid white;
            text-align: center;
            border-radius: $size;
            line-height: $size;
        }

        ul {
            @extend .list-reset;
            @extend .list-inline;
        }

        li {
            margin-right: 3px;
        }
    }

    .credits {
    }

    .newsletter-subscribe {
        label {
            font-weight: 300;
            text-transform: uppercase;
        }

        input {
            height: 61px;
            border: 0;
            color: white;
            background-color: #48494b;
        }

        .btn {
            min-width: 0;
        }
    }
    /* -------------------------------------------------- 
   Media query
  -------------------------------------------------- */
    @media (max-width: 1199px) {
    }

    @media #{$md-max} {


        .quick-links {
            margin-top: 40px;
        }

    }

    @media #{$sm-max} {
        .panaria {
            display: block;
            margin-bottom: 30px;
        }

        .quick-links {
            margin-top: 40px;
        }

        .footer-bottom {
            margin-top: $space * .5;

            .credits {
                img {
                    width: 200px;
                }
            }
        }
    }


    @media #{$xs-max} {
        padding: ($space * .5) 0;

        .panaria {
            img {
                max-width: 200px;
            }
        }


        .quick-links {
            width: 100%;
            float: none;
            margin-top: 40px;

            li {
                width: 49%;
                line-height: 2;
            }
        }

        .lang-change {
            width: 100%;
            float: none;
        }



        .socials, .credits {
            margin-bottom: $space / 4;
        }
    }
    // @media (max-width: 767px)
}
    // .main-footer
    .panaria-usa .main-footer {
        .newsletter-subscribe {
            .btn {
                @extend .btn-sand;
            }
        }

        .lang-change select {
            color: $sand;
        }

        .custom-select {
            background-image: url(/img/icone/arrow-sand.png);
        }
    }