﻿.modal {
    z-index: 3000;

    &-content {
        border-radius: 0;

        #paese-lingua & {
            margin-top: 90px;
        }
    }

    .modal-dialog {
        margin: 150px auto;

        @media #{$md-max} {
            margin: 100px auto;
        }

        @media #{$xs-max} {
            margin: 120px auto;
        }
    }
}
// .modal



/* -------------------------------------------------- 
  Protect Modal
-------------------------------------------------- */
.fancybox-slide {
    @media #{$xs-max} {
        padding: 0 !important;
    }
}
#protect-modal {
    max-width: 500px;
    text-align: center;
    .fancybox-close-small {
        display: none;
    }

    @media #{$xs-max} {
        max-width: 100%;
    }

    .logo-protect {
        max-width: 150px;
    }

    .abs {
        font-weight: bold;
        padding: 10px 0;
    }

    .text {
        font-size: 14px;
    }

    .cta {
        padding: 20px 0;
    }

    .x-close {
        position: absolute;
        right: 20px;
        cursor: pointer;
        z-index: 1;
    }
}
/* -------------------------------------------------- 
  Think Zero Modal
-------------------------------------------------- */
#think-zero-modal {
    max-width: 500px;
    text-align: center;

    .fancybox-close-small {
        display: none;
    }

    @media #{$xs-max} {
        max-width: 100%;
    }

    .logo-protect {
        max-width: 150px;
    }

    .abs {
        font-weight: bold;
        padding: 10px 0;
    }

    .text {
        font-size: 14px;
    }

    .cta {
        padding: 20px 0;
    }

    .x-close {
        position: absolute;
        right: 20px;
        cursor: pointer;
        z-index: 1;
    }
}
/* -------------------------------------------------- 
  Cersaie Modal
-------------------------------------------------- */
.modal-cersaie-link {
    display: none;
}

#cersaie-modal {
    max-width: 500px;
    text-align: center;
    padding: 0;
    overflow: visible;

    .fancybox-close-small {
        display: none;
    }

    @media #{$xs-max} {
        max-width: 100%;
    }

    img {
        width: 100%;
        max-width: 100%;
    }

    .x-close {
        position: absolute;
        right: -50px;
        top: 0px;
        background-color: white;
        padding: 10px;
        cursor: pointer;
        z-index: 1;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
        width: 50px;
        height: 50px;

        @media #{$sm-max} {
            width: 40px;
            height: 40px;
            right: 0;
            top: -40px;
        }
    }
}
/* -------------------------------------------------- 
  Bottone per modale con gallery
-------------------------------------------------- */
.modal-gallery-btn {
    @extend .btn;
    position: absolute;
    top: 0;
    right: 20px;
    color: white;
    background: $orange;
    width: 40px;
    height: 40px;
    display: block;
    z-index: 1;
    text-align: center;
    line-height: 40px;
    min-width: 0;
    padding: 0;

    &:focus, &:hover {
        cursor: pointer;
    }

    &:visited {
        color: white;
    }

    @media #{$sm-max} {
        //display: none;
    }

    
}

.fancybox-button svg path {
    stroke-width: 3 !important;
}


.modal-backdrop.in {
    opacity: .7;
}

/* -------------------------------------------------- 
  Modale con gallery
-------------------------------------------------- */
.modal-gallery {
    &.in {
        display: flex !important;
        align-items: center;
        padding: 0;
    }

    .modal-dialog {
        width: 900px;
        margin: 30px auto;

        @media #{$sm-max} {
            width: 100%;
        }
    }

    .modal-content {
        background: transparent;
        box-shadow: none;
        border: 0;

        .modal-gallery-btn {
            display: none;

            @media #{$sm-max} {
                display: block;
            }
        }
    }

    img {
        margin: 0 auto;
        max-height: 670px;

        @media #{$sm-max} {
            max-width: initial;
            max-height: 500px;
            height: 100%;
            object-fit: cover;
        }
    }

    .modal-gallery-btn {
        right: 0;
    }

    .modal {
        &-body {
            padding: 0;
            //height: 0;
            overflow: hidden;
        }
    }
    // .modal
    .slick-slide {
        img {
        }

        &.vertical-image {
            figure {
                img {
                }
            }
        }
    }

    @media (min-width: 1200px) {
        .modal-dialog {
            width: 1024px;
        }
    }

    .slick-next {
        @media #{$sm-max} {
            right: 0;
        }
    }

    .slick-prev {
        @media #{$sm-max} {
            left: 0;
        }
    }
}




/* -------------------------------------------------- 
  Image zoom
-------------------------------------------------- */
.modal-zoom-image {

    .modal-gallery-btn {
        right: 0;
    }

    .modal-body {
        padding: 0;
    }

    img {
        width: 100%;
    }

    .modal-dialog.vertical {
        width: 30%;
    }


    @media (min-width: 1200px) {
        .modal-dialog {
            width: 1024px;
        }
    }

    @media (max-width: 1024px) {
        .modal-dialog.vertical {
            width: 45%;
        }
    }
}


/* -------------------------------------------------- 
  Splash page
-------------------------------------------------- */
.splash-page {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 6666;
    display: none;

    .close {
        position: absolute;
        right: 20px;
        top: 20px;
        opacity: 1;
        z-index: 66666;

        img {
            width: 18px;
            height: 18px;
            opacity: 1;
        }
    }

    .wrap {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 98%;
        text-align: center;
        margin-left: 1%;

        video {
            max-width: 100%;
            z-index: 5555;
            max-height: 90vh;
        }
    }
}

/* -------------------------------------------------- 
  Modal Search
-------------------------------------------------- */
#modal-search {
    .tit {
        font-weight: 600;
    }

    form {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .form-control {
            height: 60px;
            width: 80%;
        }

        .btn {
            height: 60px;
            width: 20%;
            min-width: initial;

            &:hover {
                svg {
                    * {
                        fill: #000;
                        transition: all ease-in-out .3s;
                    }
                }
            }

            svg {
                * {
                    fill: #fff;
                    transition: all ease-in-out .3s;
                }
            }
        }
    }
}