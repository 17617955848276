﻿.glossary-alphabet {
  @extend .spacer-lg;
  a {
    font-size: 24px;
    font-weight: 600;
    margin-right: 10px;
    color: $sanddark;
    transition: color .4s ease-out;
    display: inline-block;
    &.active {
      color: $orange;
    }
    &:focus, &:hover {
      text-decoration: none;
      color: $orange;
    }
  }
}




.glossary-list {
  @extend .spacer-lg;
}



.glossary-item {
  @extend .spacer;

  article {
    opacity: 0;
  }
  header {
    @extend .spacer-sm;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
  }  

  & > div {
    font-weight: 300;
  }

}



.glossary-search {
  float: right;  

  svg {
    display: inline;
    transform: translateY(3px);
  }

  input {
    border: 0;
    width: 140px;
  }

  @media (max-width: 1500px) {
    float: none;
    display: block;
    margin-bottom: 20px;
  }

}