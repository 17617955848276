﻿/* -------------------------------------------------- 
  Sile per il componente link + immagine + testo
  http://pasteboard.co/2xFm3s3ru.png


  Struttura:

  .thumb-component
    figure
      img   
    .title
    .abstract
    (a.text-link)


 
  Versione con tendina all'hover

  .thumb-component.has-data-overlay
    a
      figure
        .data-overlay
          .relative
            img.protect
          ul.data-overlay-list
            li
        img   
      .title
      .abstract


-------------------------------------------------- */
.featured-news {
    padding: 50px 0;

    .row {
        display: flex;
        align-items: flex-end;

        @media (max-width: 767px) {
            flex-wrap: wrap;
            align-items: flex-start;
        }
    }

    a {
        &:hover {
            text-decoration: none;
        }
    }

    .thumb-caption {
        margin-bottom: 40px;

        .info-top {
            padding-right: 10px;
            padding-left: 0;
            text-transform: uppercase;
            color: #a6a8ac;
            font-size: 12px;
            margin-bottom: 12px;
        }

        .title {
            font-family: 'Source Sans Pro', sans-serif !important;
            font-weight: 600 !important;
            line-height: 1.2;
            font-size: 32px;
            color: #333;
            transition: all ease-in-out .3s;

            &:hover, &:focus {
                color: #be4018;
                transition: all ease-in-out .3s;
            }
        }

        .abstract {
            font-size: 14px;
            line-height: 1.8;
            font-family: "Crimson Text", serif !important;
            color: #333;
        }
    }
}

.thumb-component {
    @extend .spacer-mobile;

    @media (max-width: 767px) {
        margin-bottom: 0;
    }

    a {
        display: block;
        text-align: center;

        &, &:hover {
            color: inherit;
            text-decoration: none;
        }

        .title {
            transition: color .2s ease-out;
        }

        figure {
            @extend .img-hover-fx;
            position: relative;
            width: 100%;

            .label-cat {
                position: absolute;
                right: 20px;
                top: 0;
                padding: 10px;
                border-radius: 0;
                font-size: 12px;
                text-transform: uppercase;
                z-index: 2;

                &.magazine {
                    background: #be4018;
                    color: #fff;
                }

                &.news {
                    background: #3AB4F2;
                    color: #fff;
                }

                &.cat {
                    background: #be4018;
                    color: #fff;
                }
            }

            img {
                position: relative;
                z-index: 1;
                height: 300px !important;
                object-fit: cover;
            }
        }

        &:hover {
            .title {
                color: $orange;
            }
        }
    }

    figure {
        @extend .shadow;
        position: relative;
        margin-bottom: $space / 4;
        display: inline-block;
    }

    img {
        max-width: 100%;
        width: 100%;
    }

    .title, .abstract {
        padding-right: 10px;
        padding-left: 10px;
    }

    .title {
        // margin-bottom: $space / 4;
        @extend .spacer-xs;
    }

    .abstract {
        @extend .spacer-sm;
        font-size: 18px;
    }

    .text-link {
        margin-left: 10px;
        display: inline;
    }


    &--projects {
        .title, .abstract {
            // text-align: left;
        }

        .title {
            @extend .title-sans;
            font-weight: bold;
            font-size: 20px;
        }
    }

    @media (max-width: 991px) {
        .title {
        }
    }
} // .thumb-component




/* -------------------------------------------------- 
  Effetto zoom sulle immagini
-------------------------------------------------- */
.img-hover-fx {
  @extend .shadow;
  overflow: hidden;

  img {
    // will-change: transform;
    margin: 0;
    max-width: 100%;
    transition: transform .6s;
    height: auto !important;
  }
  &:hover {
    img {
      transform: scale(1.03);
    }
  }
}



/* -------------------------------------------------- 
  Dettagli sulla foto
-------------------------------------------------- */
.data-overlay {
  $transitionDuration: .2s;
  text-align: left;
  position: absolute;
  height: 100%;
  width: 100%;
  // background: rgba($orange, .6);
  z-index: 1;
  color: white;
  opacity: 0;
  transition: opacity $transitionDuration;

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 0;
    background: rgba($orange, .6);  
    top: 0;
    left: 0;
    will-change: width;
    transition: width $transitionDuration * 2;
  }

  .relative {
    width: 100%;
    height: 100%;
  }

  .data-overlay-list {    
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 1;
  }

  ul {
    @extend .list-inline;
    @extend .list-reset;    
    text-transform: uppercase;
    font-size: 14px;
  }

  li {
    margin-right: 20px;
    white-space: nowrap; 
    float: left;
    font-size: 12px;
  }  

  .protect {
    position: absolute;
    top: 30px;
    right: 20px;
    width: 120px;
    transform: none;
    z-index:1;
    height: auto;
  }

  .protect-usa {
    @extend .protect;

  }

  &:hover {
    opacity: 1;

    li {
      opacity: 1;
    }

    &:after {
      width: 100%;
    }
  }

  @media (max-width: 992px) {
    display: none;
  }

}