﻿@import 'theme';


/* -------------------------------------------------- 
  Header
-------------------------------------------------- */
.magazine-header {
    background-color: $orange;
    text-align: center;
    padding: 60px 0 0 0;
    margin: 0 0 50px 0;
    color: white;

    a {
        color: white;
    }

    .title {
        @extend .voor;
        margin-bottom: $space / 3;
    }

    .subtitle {
        font-size: 22px;
        margin-bottom: 60px;
    }

    .cover {
        img {
            @extend .shadow;
            max-width: 100%;
            margin-bottom: 60px;
        }
    }

    @media (max-width: 991px) {
        padding: ($space/4) 0 0;

        .cover {
            padding-left: 0;
            padding-right: 0;
        }
    }

    @media #{$sm-max} {
        padding: 90px 0 0;
    }

}





/* -------------------------------------------------- 
  Body
-------------------------------------------------- */
.magazine-body {
    padding: 0 0 60px 0;

    p {
        margin-bottom: 40px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .paragraph-title {
        color: $orange;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 30px;
    }

    .tutteleapplicazioni td {
        white-space: normal !important;
    }

    @media #{$sm-max} {
        .table-scelta-sicura {
            width: 600px !important;

            img.img-scelta-sicura {
                width: 600px !important;
                height: auto !important;
            }
        }
    }

    @media #{$xs-max} {
        .table-scelta-sicura {
            width: 300px !important;

            img.img-scelta-sicura {
                width: 300px !important;
                height: auto !important;
            }
        }
    }
}



/* -------------------------------------------------- 
  Gallery
-------------------------------------------------- */
.magazine-gallery {
    background-color: $orange;
    color: white;
    padding: ($space * .75) 0;
    margin-bottom: 60px;

    .slick {
        &-slide {
            text-align: center;
            padding: 0 20px;
            color: #fff;

            @media #{$sm-max} {
                padding: 0;
            }
        }

        &-next:before, &-prev:before {
            color: white !important;
        }
    }

    figure {
        position: relative;
    }

    img {
        box-shadow: 0px 40px 30px -40px rgba(0, 0, 0, 0.4);
        max-width: 100%;
        margin-bottom: 35px;
        //height: 300px;
        max-height: initial;
    }

    p {
        //margin-top: -20px;
        //margin-bottom: 20px;
    }

    .slick-dots {
        bottom: -45px;
    }
}



/* -------------------------------------------------- 
  Filtri listing
-------------------------------------------------- */
.magazine-filters {
    $border: 1px solid $orange;
    margin-top: $space / 2;
    margin-bottom: $space * .75;
    text-align: center;


    &-wrap {
        border-top: $border;
        border-bottom: $border;
        padding: $space/3 0;

        @media #{$sm-max} {
            padding: 20px 0;
        }
    }

    .categories {
        line-height: 1;
        overflow: hidden;

        &__title {
            float: left;
        }

        .cat-mobile {
            font-size: 18px;
            font-weight: 600;
            display: none;

            @media #{$sm-max} {
                display: block;
            }

            &.open {
                margin: 0 0 20px;
            }
        }

        ul {
            @extend .list-reset;
            @extend .list-inline;
            margin-bottom: 0;
            display: flex;
            justify-content: center;

            @media #{$sm-max} {
                flex-direction: column;
                display: none;
            }
        }

        li {
            margin: 0 15px;

            @media #{$sm-max} {
                display: block !important;
            }
        }

        a {
            text-transform: uppercase;
            color: $black;
            font-size: 22px;
            line-height: 1.2;
            font-weight: 600;
            margin-bottom: 20px;

            @media #{$md-max} {
                font-size: 20px;
            }

            @media (max-width: 767px) {
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                flex-direction: column;
            }

            &.active {
                &:after {
                    content: '';
                    position: relative;
                    margin: 0 auto;
                    bottom: 0;
                    left: 0;
                    width: 30px;
                    height: 3px;
                    background-color: #be4018;
                    display: block;
                    margin-top: 10px;

                    @media #{$sm-max} {
                        margin-bottom: 20px !important;
                    }
                }
            }

            &:hover, &:focus {
                text-decoration: none;

                &:after {
                    content: '';
                    position: relative;
                    margin: 0 auto;
                    bottom: 0;
                    left: 0;
                    width: 30px;
                    height: 3px;
                    background-color: #be4018;
                    display: block;
                    margin-top: 10px;
                }
            }

            &:after {
                content: '';
                position: relative;
                margin: 0 auto;
                bottom: 0;
                left: 0;
                width: 30px;
                height: 3px;
                background-color: transparent;
                display: block;
                margin-top: 10px;
            }
        }

        .all a {
            text-transform: none;
        }
    }
    // .categories
    .tags {
        display: none;
        margin-top: 20px;

        ul {
            @extend .list-reset;
            @extend .list-inline;
            margin-bottom: 0;
        }

        li {
            opacity: 0;
            margin: 0 15px;

            &.active a {
                opacity: 1;
            }
        }

        a {
            opacity: .5;
            color: $black;
            text-decoration: none;
            background: url(/img/icone/tag_b.png) no-repeat left center;
            background-size: 17px;
            padding-left: 22px;
            font-size: 14px;
        }

        .all a {
            background: none;
            padding-left: 0;
            font-weight: 500;
        }
    }
}


/* -------------------------------------------------- 
  Masonry Pagina listing
-------------------------------------------------- */
.magazine-listing {
    margin-top: 60px;

    @media (max-width: 767px) {
        margin-top: 40px;
    }


    footer {
        text-align: center;
        margin-bottom: $space;
    }

    .grid-sizer,
    .grid-item {
        width: 22%;
        margin-bottom: 40px;
        padding-right: 40px;
        float: left;
    }

    .grid-item--width2 {
        width: 48%;
    }

    .gutter-sizer {
        width: 4%;
    }



    .thumb-component {
        figure {
            margin-bottom: $space / 4;
            width: 100%;
            img {
                height: 250px;
                object-fit: cover;
            }
        }

        .info-top {
            padding-right: 10px;
            padding-left: 10px;
            text-transform: uppercase;
            color: lighten($black, 50%);
            font-size: 12px;
            margin-bottom: $space / 10;
        }

        .title {
            margin-bottom: $space / 10;
        }

        .abstract {
            @extend .font-xs;
        }
    }

    @media (max-width: 1399px) {
        .grid-sizer,
        .grid-item {
            width: 22%;
            padding-right: 0;
        }

        .grid-item--width2 {
            width: 48%;
        }

        .gutter-sizer {
            width: 4%;
        }
    }


    @media (max-width: 991px) {
        .grid-sizer,
        .grid-item {
            width: 48%;
            padding-right: 0;
        }

        .grid-item--width2 {
            width: 48%;
        }

        .gutter-sizer {
            width: 4%;
        }
    }


    @media (max-width: 767px) {
        .grid-sizer,
        .grid-item {
            width: 100%;
            padding-right: 0;
        }

        .grid-item--width2 {
            width: 100%;
        }
    }
}
/* -------------------------------------------------- 
  Correlati
-------------------------------------------------- */
.related-magazine {
    padding-top: 0;

    .title {
        @extend .spacer;
    }
}
/* -------------------------------------------------- 
  Blockquote
-------------------------------------------------- */
blockquote {
    @extend .title;
    @extend .title-lg;
    padding: 0;
    border: 0;
    margin-bottom: $space * .75;
    max-width: 580px;
    line-height: 1.4;
    // Virgolette quote
    // Commentate su richiesta cliente
    /*
  &:before {
    content: '“';
    color: $orange;
    margin-left: -25px;
  }
  &:after {
    content: '”';
    color: $orange;
  }
  */
    @media (max-width: 991px) {
        margin-left: 20px;
    }
}

/* -------------------------------------------------- 
  Gallery da Cotto d'Este
-------------------------------------------------- */

.inverted-gallery {
    position: relative;

    .container-fluid {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        margin-right: auto;
        margin-left: auto;
        max-width: none;

        @media (max-width: 991px) {
            margin-left: 20px;
            margin-right: 20px;
            width: auto;
        }
    }

    footer {
        /*height: 32px;
        background-color: #ccc;*/
        @media (max-width: 991px) {
            display: none;
        }

        .justify-content-around {
            position: relative;

            .slick-prev {
                left: 40px;
            }

            .slick-next {
                right: 0;
            }

            .slick-prev, .slick-next {
                top: 0;
            }

            .slick-prev img, .slick-next img {
                width: 100%;
                height: 32px;
            }
        }
    }
}

.inverted-gallery .slick-slide img {
    height: 550px;
    width: auto;

    @media (max-width: 991px) {
        height: auto;
        width: 100%;
    }
}

.inverted-gallery .slick-list {
    z-index: 2;
}

@media screen and (min-width: 992px) {
    .inverted-gallery .slick-slide {
        margin-right: 80px;
    }
}

@media print {
    .inverted-gallery {
        display: none;
    }
}

.gallery-dots {
    display: none;

    @media (max-width: 1200px) {
        display: block;
    }

    ul {
        position: relative;
        bottom: auto;
        top: auto;
        margin-bottom: 30px;
        top: -10px;

        li {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;

            button {
                background: #fff;
                border: 2px solid #f26122;
                border-radius: 15px;
                width: 14px;
                height: 14px;
                transition: all .3s ease-in-out;
            }

            &.slick-active button {
                background: #f26122;
            }
        }
    }
}

.mb-6 {
    margin-bottom: 80px;
}

.prog__intro {
    .col-lg-6 {
        img {
            @media (max-width: 1200px) {
                width: 100%;
            }
        }
    }
}

/* -------------------------------------------------- 
  New magazine
-------------------------------------------------- */
.new-magazine {
    margin-bottom: 60px;

    .full-width-slider {

        .item {
            padding-bottom: 40px;
            position: relative;

            .immagine {
                margin-bottom: 30px;

                img {
                    width: 100%;
                    box-shadow: 0px 40px 60px -50px rgba(0, 0, 0, 0.4);
                }
            }

            .abstract {
                text-align: center;
                /*font-family: 'Voor', serif;
                font-size: 20px;*/
                font-weight: 600;
            }
        }
    }
    /*.wrap-txt-alternate {
        position: relative;

        &:before {
            content: "";
            width: 200px;
            height: 30px;
            background: $orange;
            position: absolute;
            bottom: -30px;
            right: 0px;
            clear: both;
            z-index: 1;

            @media screen and (max-width: 767px) {
                display: none;
            }
        }
    }*/

    .img-txt-alternate {
        z-index: 2;

        .item {
            .immagine {
                margin-bottom: 30px;
                position: relative;

                img {
                    width: 100%;
                    box-shadow: 0px 40px 60px -50px rgba(0, 0, 0, 0.4);
                    position: relative;
                    z-index: 2;
                }

                &:before {
                    content: "";
                    width: 100px;
                    height: 10px;
                    background: $orange;
                    position: absolute;
                    bottom: -10px;
                    right: 0;
                    clear: both;
                    z-index: 1;

                    @media screen and (max-width: 767px) {
                        display: none;
                    }
                }
            }

            .abstract {
                text-align: center;
                /*font-family: 'Voor', serif;
                font-size: 20px;*/
                font-weight: 600;

                @media #{$xs-max} {
                    padding: 0 0 20px;
                }
            }
        }
    }

    .slick-prev {
        z-index: 1;
    }

    .slick-dots {
        bottom: -35px;

        li {
            width: 10px;
            height: 10px;

            button {
                border: 1px solid #f26122;
                border-radius: 50%;
                padding: 0;
                width: 10px;
                height: 10px;

                &:before {
                    content: '';
                    opacity: 1;
                    position: relative;
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    .slick-dots li.slick-active button {
        background: #f26122 !important;
    }

    .ico {
        width: 60px;
        height: 60px;
        background-color: #f26122;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -30px;
        margin-top: -30px;
        z-index: 3;
        border-radius: 200px;

        .playicon {
            position: absolute;
            margin-top: 13px;
            margin-left: 20px;
            border-left: 28px solid #fff;
            border-right: 18px dashed transparent;
            border-top: 18px dashed transparent;
            border-bottom: 18px dashed transparent;
        }
    }
}

/* Protect Template */
.tmp-protect {

    .magazine-header {
        margin-bottom: 60px;

        .text-center {
            margin-bottom: 40px;
        }

        .btn-land {
            color: #be4018;
        }
    }

    .related-magazine {

        .abstract {
            margin-bottom: 30px;
        }

        .col-sm-6.col-md-4 {
            margin-bottom: 40px;
        }

        .abstract.test {
            display: none;
        }

        .abstract {
            min-height: 92px;
        }

        .load-more {
            padding-bottom: 60px;

            .link {
                text-decoration: underline;
                font-weight: bold;
                color: black;
                transition: all .3s ease-in-out;
                display: block;

                &:hover {
                    opacity: .8;
                }

                @media (max-width: 767px) {
                    font-size: 16px;
                }
            }
        }
    }

    .magazine-body {
        padding-bottom: 90px;

        .play-video {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            /*height: 584px;*/
            height: 390px;
            position: relative;
            z-index: 3;
            max-width: 1460px;
            margin: auto;
            margin-bottom: 40px;

            &:after {
                content: '';
                background: #878887;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                opacity: .1;
            }

            .circle {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                /*width: 196px;
                height: 196px;*/
                height: 100px;
                width: 100px;
                transform: translateX(-50%) translateY(-50%);
                cursor: pointer;
                z-index: 5;
            }

            .icon--play-progress-background {
                display: block;
                position: absolute;
                width: 100px;
                height: 100px;
                top: 0;
                left: 0;
                stroke: #fff;
                fill: #fff;
                opacity: 1;
            }

            .icon--play {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 20px;
                height: 20px;
                margin-left: -9px;
                margin-top: -9px;
                fill: #000;
            }

            @media (max-width: 1199px) {
                height: 365px;
            }

            @media (max-width: 575.98px) {
                height: 144px;
            }
        }

        .btn-land {
            margin-top: 40px;
            margin-left: 75px;

            @media (max-width: 1199px) {
                margin-left: 15px;
            }
        }

        .title {
            font-weight: 600;
            margin-left: 60px;

            @media (max-width: 1199px) {
                margin-left: 0px;
                margin-top: 60px;
            }
        }

        .desc {
            margin-left: 60px;

            @media (max-width: 1199px) {
                margin-left: 0px;
            }
        }

        .link {
            text-decoration: underline;
            font-weight: bold;
            color: black;
            transition: all .3s ease-in-out;

            &:hover {
                opacity: .8;
            }
        }
    }

    .btn-land {
        font-weight: bold;
        padding: 20px;
    }

    @media (max-width: 575.98px) {

        .fancybox-slide.fancybox-slide--iframe.fancybox-slide--video.fancybox-slide--current.fancybox-slide--complete {
            padding: 0 !important;
        }

        .fancybox-content {
            height: 180px !important;
        }

        .btn-land {
            padding: 15px 12px;
            font-size: 14px;
        }
    }

    @media (max-width: 340px) {

        .btn-land {
            padding: 10px;
            font-size: 13px;
        }
    }
}