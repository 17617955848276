﻿.guides-header {
  @extend .default-header;
  @extend .spacer-lg;
  .title {
    color: #f26122;
  }
}







/* -------------------------------------------------- 
  Effetto zoom sulle immagini
-------------------------------------------------- */
.guide-dettaglio {

  .header-padding,
  .magazine-header,
  .magazine-gallery {
    //background: $sand;
    //color: $black;
  }

  .magazine-header {
    .title {
      /*@extend .title-sans;*/
    }
  }

  


  .magazine-gallery {
    //color: $black;

    .slick {

      // Frecce
      &-next:before, &-prev:before {
        //color: $black;
      }

      // Punti
      &-dots {
        li {      
          button {
            //background: $black;
            //border: 2px solid $sand;
          }
          &.slick-active button {
            //border: 2px solid $black;
            //background: $sand;
          }
        }
      }
    }
  } // .magazine-gallery



}





.thumb-component.guides {
  text-align: left;
  .title {
    @extend .title-sans;
    font-size: 20px;
    line-height: 1.2;
  }
  .abstract {
    
  }
}