﻿/* -------------------------------------------------- 
  Newsletter
-------------------------------------------------- */
.newsletter-subscribe {
    padding: 45px 0;

    .title-xl {
        font-weight: 600;
        padding: 0 0 10px;
    }

    .txt-nl {
        @media #{$sm-max} {
            padding-bottom: 20px;
        }
    }

    .row {
        align-items: flex-end;
        display: flex;

        @media #{$sm-max} {
            flex-wrap: wrap;
        }
    }

    p {
        margin: 0;
    }

    form {
        .input-group {
            display: flex;
            flex-wrap: wrap;

            input {
                height: 61px;
                width: 80%;

                @media #{$sm-max} {
                    width: 70%;
                }
            }

            span {
                width: 20%;

                @media #{$sm-max} {
                    width: 30%;
                }

                .btn {
                    width: 100%;
                    color: #fff;

                    &:after {
                        height: 99%;
                    }
                }
            }
        }
    }
}
