﻿/* -------------------------------------------------- 
  Header
-------------------------------------------------- */
.agent {

  &__section {
    @extend .section;
    padding-top: 60px;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    @extend .spacer-lg;
    padding: 0 15px;
    flex: 1 1 50%;
    
    header {
      @extend .spacer-sm;
    }    

  }

  &__name {
    font-size: 24px;
  }

  &__tel, &__cell {
    margin-left: 10px;
    margin-right: 10px;
  }

}  // .agent