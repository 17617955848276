﻿$textMaxWidth: 1200px;


/* -------------------------------------------------- 
  Hero
-------------------------------------------------- */
.hero {
    height: 0;
    padding-bottom: 36%;
    overflow: hidden;
    position: relative;
    text-align: center;

    .slider {
        img {
            width: 100%;
        }
    }

    &-text {
        position: absolute;
        z-index: 10;
        color: white;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);

        p {
            font-size: 22px;
        }

        & > div {
            position: relative;

            &:after {
                content: '';
                background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.3) 30%, rgba(0, 0, 0, 0.3) 70%, transparent);
                position: absolute;
                width: 100%;
                height: 300%;
                left: 0;
                top: -100%;
                z-index: -1;
            }
        }
    }


    .title {
        text-shadow: 0 2px 10px rgba(0,0,0,.5);


        &-quotes {
            fill: white;
        }
    }

    .title, p {
        max-width: $textMaxWidth;
        margin-left: auto;
        margin-right: auto;
    }
    // Versione con testo in basso (pagina interna)
    &.hero-text-bottom {
        .hero-text {
            bottom: 40px;
            top: auto;
            transform: none;
        }
    }


    .slick {

        &-slider {
            position: absolute;
            z-index: 5;
            width: 100vw;
        }

        &-slide {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center top;
            height: 0;
            padding-bottom: 36%;
            /*background-size: contain!important;*/
            background-attachment: fixed;
            width: 100%;
            max-width: 100vw;

            img {
                display: none;
                width: 100%;
            }
        }

        &-dots {
            bottom: 40px;

            li {

                &, button, button:before {
                    width: 50px;
                    height: 2px;
                }

                button:before {
                    content: '';
                    background: white;
                }
            }
        }

        @media #{$xs-max} {
            &-dots {
                bottom: 20px;
            }
        }
    }
    // .slick



    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

        .slick-slide {
            background-attachment: initial;
        }
    }



    @media (max-width: 1199px) {
        &-text > div {
            padding-left: 15px;
            padding-right: 15px;
        }

        @media(min-width:992px) {
            &.hero-project {
                padding-bottom: 55%;
            }
        }
    }

    @media #{$md-max} {
        &.hp-hero, &.azienda-hero {
            padding-top: 60px;
        }

        &.hero-product {
            padding-top: 101px;
        }
    }

    @media #{$sm-max} {
        &.hero-project {
            padding-top: 50%;
        }
    }

    @media #{$xs-max}{
        &.hero-project {
            padding-top: 75%;

            .hero-text {
                padding: 5px 0;
            }
        }
    }


    @media #{$sm-max} {
        height: auto;
        padding-bottom: 0;
        max-height: initial;
        min-height: initial;
        padding-top: 60px;

        .title {
            text-shadow: none;

            &-quotes {
                fill: white;
                height: 20px;
            }
        }


        &-text {
            padding: 40px 0;
            position: relative;
            top: initial;
            transform: initial;
            z-index: initial;
            background: $orange;
            height: auto;

            & > div:after {
                content: none;
            }
        }

        &.hero-text-bottom {
            .hero-text {
                bottom: initial;
            }
        }



        .slick {
            &-slider {
                position: relative;
                margin: 0;
                z-index: 0;
            }

            &-slide {
                height: auto;
                padding-bottom: 0;

                img {
                    display: block;
                }
            }
        }
    }

    &-project {
        background-size: cover;
        background-position: center;
    }
} // .hero


