﻿$h: 120px;
$mobileH: 75px;
$mobileZIndex: 100;
$mobileBreakpoint: 991px;
$valueFontSize: 24px;
$valueFontSizeMaxLg: 20px;

/* -------------------------------------------------- 
  Selectbar
-------------------------------------------------- */
.selectbar {


    &-wrap {
        @extend .shadow;
        display: flex;
        background-color: $sand;
        justify-content: space-around;
    }


    &-item {
        flex: 1 0 auto;
        border-left: 1px solid rgba(0,0,0,.2);
        text-align: center;
        position: relative;
        height: $h;
        transition: all .4s ease-out;

        &--unselected {
            background-color: rgba(0,0,0,0.05);
            color: #f26122;
        }

        &:first-child {
            border-left: 0;
            border-top: 0;
        }

        & > div {
        }

        &:hover {
            cursor: pointer;
            // z-index:10;
        }
    }


    &-label {
        color: $orange;
        font-size: 13px;
        position: absolute;
        left: 20px;
        top: 10px;
        z-index: 5;
        text-align: left;
        line-height: 1.3;
    }


    &-value {
        font-size: $valueFontSize;
        line-height: $h;
    }


    &-btn {
        display: block;
        line-height: $h;
        color: white;
        position: relative;
        text-transform: uppercase;

        span {
            position: relative;
            z-index: 1;
        }

        &-wrap {
            background-color: $orange;
            border-left: 0;
        }

        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 100%;
            left: 0;
            top: 0;
            background: rgba(255,255,255,.2);
            transition-property: width, opacity;
            transition-duration: .2s;
            transition-timing-function: ease-out;
            z-index: 0;
        }

        &:focus, &:hover {
            text-decoration: none;
            color: white;

            &:after {
                width: 100%;
            }
        }
    }
    // .selectbar-btn


    &-input {
        border: 0;
        background: transparent;
        float: left;
        width: calc(100% - 50px);
        line-height: 120px;
        font-size: $valueFontSize;
        text-align: center;

        &:focus, &:hover {
            outline: none;
        }

        &-wrap {
            border-left: 1px solid rgba(0, 0, 0, 0.2);
            line-height: $h;

            svg {
                width: 40px;
                height: 40px;
                position: absolute;
                top: calc(50% - 20px);
                right: 30px;
                opacity: 0.2;
            }
        }



        @media (max-width: 1299px) {
            font-size: $valueFontSizeMaxLg;
        }
    }
    // .selectbar-input


    .x-close {
        @extend .x-close--white;
        display: none;
        right: 20px;
        top: 29px;
        background-color: $orange;
        width: 90px;
        height: 35px;
        background-size: 17px;
        background-repeat: no-repeat;
        background-position: 90% 50%;
        position: fixed;
        z-index: $mobileZIndex + 1;
        color: #fff;
        text-transform: uppercase;
        padding: 0 10px;

        &:hover, &:focus {
            text-decoration: none;
        }

        @media (max-width: 768px) {
            width: 130px;
        }
    }

    @media (max-width: 1399px) {
        &-value {
            font-size: $valueFontSize;
            line-height: $h;
        }
    }


    @media (max-width: 1299px) {
        &-label {
            left: 10px;
            top: 15px;
            font-size: 11px;
        }

        &-value {
            font-size: $valueFontSizeMaxLg;
        }
    }


    @media (max-width: $mobileBreakpoint) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        padding: 40px 20px 20px;
        background: rgba($black, .6);
        z-index: $mobileZIndex;
        display: none;
        overflow: scroll;

        &-wrap {
            flex-direction: column;
            margin-top: 40px;
        }

        &-item {
            border: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.2);
            height: $mobileH;
        }

        &-label {
            top: 10px;
        }

        &-btn, &-value {
            line-height: $mobileH;
        }

        .x-close {
            display: flex;
            align-items: center;
            margin-top: 40px;
        }
    }

    @media (max-width: 768px) {
        padding: 64px 20px 100px;
    }
} // .selectbar



/* -------------------------------------------------- 
  Lista di voci
-------------------------------------------------- */
.selectbar-list {
  $w: 300px;
  position: absolute;
  background: $orange;
  width: $w;
  height: $h;
  max-height: $h * 3;
  opacity: 0;
  top: 0;
  left: -1px;
  transition: all .4s;
  z-index: 20;
  box-shadow: 0px 6px 20px -6px rgba(0, 0, 0, 0.2);
  pointer-events: none;
  width: 100%;
  

  &.show {
    opacity: 1;
    height: auto;
    pointer-events: all;
  }

  &.fixRightPosition {
    left: auto;
    right: 0;
  }


  a {
    display: block;
    color: white;
    border-top: 1px solid rgba(255,255,255,.2);
    font-size: 16px;
    height: 60px;
    line-height: 60px;
    text-transform: uppercase;    
    background-color: $orange;

    &:hover {
      color: $orange;
      background: white;
      text-decoration: none;
    }
  }


  // Scrollbar
  &.has-scrollbar {
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
	    background-color: transparent;
    }
 
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	    background-color: #F5F5F5;
    }
 
    &::-webkit-scrollbar-thumb {
      background-color: #000000;
	    border: 2px solid #555555;
    }
  }


  @media #{$sm-max} {
        //width: 230px;
    
    &.show {      
      top: -5px!important;  // !important per sovrascrivere il top dato via JS per il desktop
    }    
  }  

  @media #{$xs-max} {
    //width: 280px;
  }

} // .selectbar-list




/* -------------------------------------------------- 
  Attivatore mobile
-------------------------------------------------- */
.selectbar-mobile-open {

  &-wrapper {
    text-align: center;
    display: none;
  }  

  @media (max-width: $mobileBreakpoint) {
    &-wrapper {
      display: block;
    }
  }
}



