﻿@import 'theme';


/* -------------------------------------------------- 
  Global
-------------------------------------------------- */
body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  line-height: 1.7;
  letter-spacing: 1px;

  @media (max-width: 767px) {
    font-size: 14px;
  }


}

a {
  color: $orange;
  &:hover, &:focus {
    outline: none;
  }
} 


 

/* -------------------------------------------------- 
  Fonts
-------------------------------------------------- */
.sans-serif {
  font-family: 'Source Sans Pro', sans-serif !important;
}
.voor {
  font-family: 'Crimson Text', serif;
}
.serif-usa {
  font-family: 'Tinos', serif;
}



/* -------------------------------------------------- 
  Override dei font per i vari blocchi
-------------------------------------------------- */
// Bastone SOURCE
.hero .title, .scheda-intro .title, .editorial .title-scheda, .editorial .editorial-title, .scheda-tanti-motivi .title, .feature-item .name, .full-gallery .title, .scheda-gamma .title, .scheda-download .title, .scheda-cta .title-sans, .hp-search .title, .section .title, .listing-results .title, .listing-search .title, .default-header .title, .guide-dettaglio .title, .magazine-listing .thumb-component .title, .magazine-header .title, blockquote {
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight:600!important;
}
// Graziato CRIMSON
.feature-item p, .slider .abstract, .editorial .editorial-copy, .section .abstract, .listing-results .abstract, .send-project .copy, .default-header .subtitle, .guide-dettaglio .subtitle, .magazine-body p, .magazine-body ul, .default-header .abstract, .magazine-listing .thumb-component .abstract, .magazine-header .subtitle, .new-magazine .full-width-slider .item .abstract, .new-magazine, .hero-text p, .hp-highlights .section-abstract, .timeline-copy, .listing-search .abstract, #profilo-aziendale .title {
    font-family: 'Crimson Text', serif!important;
}

  

/* -------------------------------------------------- 
  Link
-------------------------------------------------- */
.text-link {
  position: relative;
  font-weight: 600;
  &:after {
    content: '';
    border-bottom: 2px solid;
    width: 0;
    height: 2px;
    background: $orange;
    position: absolute;
    bottom: 0;
    left: 0;
    will-change: width;
    transition: width .2s ease-out, color .2s ease-out;
  }
  &:focus, &:hover {
    text-decoration: none;
    color: $orange;
    &:after {
      width: 100%;
    }
  }
}  



/* -------------------------------------------------- 
  Dimensione testo
-------------------------------------------------- */
.font {

  &-xs {
    font-size: 14px;
    line-height: 1.8;
  }

  &-lg {
    font-size: 24px;
    @media #{$xs-max} {font-size: 18px;}
  }

  &-xl {
    font-size: 34px;
    @media #{$xs-max} {font-size: 20px;}
  }
}

 

/* -------------------------------------------------- 
  Titles
-------------------------------------------------- */
@for $i from 1 through 6 {
  h#{$i} { margin: 0;}
}


.title {
  @extend .voor;
  line-height: 1.2;
  font-weight: normal;
  
  // Generazione font-size dei titoli
  $sizes: (
    md: 24,
    lg: 32,
    xl: 44,
    xxl: 67
  );
  @each $key, $value in $sizes {
    &-#{$key} { font-size: #{$value}px }
  }  
 

  &-quotes {
    width: 40px;
    height: 35px;

    &--orange {
      path {
        fill: $orange;
      }
    }

    &.quotes-sm {
      width: 25px;
    }
  }

  @media (max-width: 1199px) {

    // Generazione font-size dei titoli
    $sizes: (
      lg: 28,
      xl: 40,
      xxl: 60
    );
    @each $key, $value in $sizes {
      &-#{$key} { font-size: #{$value}px }
    }

  }


  @media (max-width: 767px) {

    $sizes: (
      // lg: 30,
      xl: 36,
      xxl: 36
    );
    @each $key, $value in $sizes {
      &-#{$key} { font-size: #{$value}px }
    }


    &-quotes {
      width: 25px;
      height: auto;
    }

  }

  &-sans {
    @extend .sans-serif;
    font-weight: 500;
  }

  &-usa {
    @extend .title-sans;
    text-transform: uppercase;
    font-weight: 700;
  }


} // .title









/* -------------------------------------------------- 
  Section
-------------------------------------------------- */
.section-title {
  @extend .title-sans;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: $space * .75;
  text-align: center;
  text-transform: uppercase;
}





/* -------------------------------------------------- 
  Testo inserito dall'utente
-------------------------------------------------- */
.user-text {

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    padding-left: 21px;
  }
}










