﻿/* -------------------------------------------------- 
  Hero
-------------------------------------------------- */
.panaria-usa .hp-hero {
  @media #{$sm-max} {
    .title-quotes path { fill: $black; }
  }  
}





/* -------------------------------------------------- 
  Search
-------------------------------------------------- */
.hp-search {
  padding: $space * .75 0 0;

  .title {
     margin-bottom: $space / 2;
     text-align: center;
   }
}








/* -------------------------------------------------- 
  "Sentirsi a casa"
-------------------------------------------------- */
.hp-highlights {
  .title {
    @extend .spacer;
  }
  .thumb-component .abstract {
    font-size: 16px;
  }
}

.panaria-usa {
 .hp-highlights {
    .section-abstract {
      @extend .font-lg;
    }
  } 
  .thumb-component {
    text-align: left;
  }
}




/* -------------------------------------------------- 
  Magazine
-------------------------------------------------- */
.hp-magazine {
    @extend .section;

    &.hp-spazi {
        background-color: #f6f3eb;
    }

    .title {
        // @extend .title-lg;
        font-family: 'Crimson Text', serif;
        margin-bottom: $space / 3;
    }

    .slider {
        padding-left: 40px;

        .slick-dots {
            button {
                background-color: #ccc;
                border-radius: 14px;
                width: 12px;
                height: 12px;

                &:before {
                    font-size: 0px;
                    text-indent: -666px;
                }
            }

            li.slick-active button {
                background-color: #666;
            }
        }
    }

    @media #{$sm-max} {
        .slider {
            padding-left: 0px;
        }
    }

    .article {
        margin-right: 40px;
    }

    footer {
        padding-top: $space / 2;
    }

    .thumb-component {
        .title {
            @extend .title-sans;
            @extend .title-md;
            @extend .spacer-xs;
        }
    }

    @media (max-width: 1199px) {
        .article {
            width: 540px;
        }
    }

    @media #{$sm-max} {
        .article {
            width: 400px;
        }
    }

    @media #{$xs-max} {
        .article {
            width: initial;
            margin-right: 0;

            figure, h4 {
            }

            img {
                width: auto
            }
        }
    }
}