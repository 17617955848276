﻿.roomvo-btn {
    border: 0;
    padding: 0;
}
.roomvo--button {
    position: static;
    bottom: 60px;
    left: 0;
    right: 0;
    z-index: 10;
    text-align: right;
    padding-bottom: 0;
    display: flex;
    justify-content: flex-end;

    @media #{$xs-max} {
        min-width: initial;
    }

    &--fixed {
        position: fixed;
    }

    .btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: auto;
        min-width: initial;

        svg {
            margin-right: 10px;
        }

        #phone {
            transition: all ease-in-out .3s;
        }

        &:hover {
            #phone {
                stroke: #be4018;
                transition: all ease-in-out .3s;
            }
        }
    }

    @media #{$xs-max} {
        .btn {
            //width:100%;
            justify-content: center;
            padding: 14px 14px;
            align-items: center;
            min-width: initial;

            &--with-icon--text {
                display: none;
            }

            svg {
                border: 0;
                margin: 0;
                width: 24px;
                height: auto;
            }
        }
    }

    &.bottom-page {
        @media(min-width:991px) {
            margin-bottom: 80px;
        }
    }
}
