﻿/* -------------------------------------------------- 
  Icone
-------------------------------------------------- */
.icon-scheda {
    @include square(110px);
    background-image: url(/img/scheda/features.png);
    background-size: cover;
    margin: 0 auto;
    margin-bottom: $space / 5;
    border-radius: 110px;
    display: inline-block;

    @media #{$md-max} {
        p {
            font-size: 14px;
        }
    }

    @media #{$xs-max} {
        @include square(100px);
        border-radius: 100px;
    }
}

$icons: 'spessori', 'formati', 'decorazioni', 'ampiezza', 'estetica', 'leggerezza', 'versatilita', 'funzionalita', 'ecologia', 'posa', 'manutenzione', 'ambiente';

@for $i from 1 through length($icons) {
    .icon-#{nth($icons, $i)} {
        background-position: -#{$i * 110}px 0;

        @media #{$xs-max} {
            background-position: -#{$i * 100}px 0;
        }
    }
}



/* -------------------------------------------------- 
  Scheda title
-------------------------------------------------- */
.title-scheda {
    @extend .title-sans;
    @extend .title-lg;
    @extend .spacer-sm;
    text-align: center;
}




/* -------------------------------------------------- 
  Box caratteristiche
-------------------------------------------------- */
.row.features {
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
}

.safari .row.features {
    &::after,
    &::before {
        display: none;
    }
}


.feature-item {
    text-align: center;
    margin-bottom: $space/3;

    &-left {
        text-align: left;
    }

    .icon {
    }

    .name {
        @extend .spacer-xs;
        color: $orange;
    }

    @media #{$md-max} {
        flex: 1 0 50%;

        p {
            font-size: 15px;
        }
    }

    @media #{$xs-max} {
        flex: 1 0 100%;
    }
}
// .feature-item




/* -------------------------------------------------- 
  Header
-------------------------------------------------- */

.panaria-usa {
    @media #{$sm-max} {
        .hero-text {
            background-color: $sand;
            color: $black;
        }
    }
}


/* -------------------------------------------------- 
  Intro
-------------------------------------------------- */
.scheda-intro {
    @extend .section;
    padding: ($space/2) 0;

    [class*="title"] {
        /*margin-bottom: $space/2;*/
    }

    .btn {
        //margin-top: $space / 2;
    }
}

.panaria-usa .scheda-intro {
    padding: ($space/2) 0 0 0;
}



/* -------------------------------------------------- 
  Fascia CTA
-------------------------------------------------- */
.scheda-cta {
    @extend .section;
    background: $orange;
    color: white;

    .article {
        text-align: center;
    }

    [class*="title"], .abstract {
        margin-bottom: $space * .25;
    }

    [class*="title"] {
    }

    .abstract {
    }
}



/* -------------------------------------------------- 
  Gamma
-------------------------------------------------- */
.scheda-gamma {
    @extend .section;
    padding-bottom: 0;

    .btn--tab {
        font-size: 32px;
        line-height: 1.2;
        font-weight: 600;
        color: #333;
        display: block;
        margin-bottom: 20px;

        @media #{$xs-max} {
            font-size: 16px;
        }

        &:hover, &:focus {
            text-decoration: none;
            box-shadow: none;

            &:before {
                background: transparent !important;
                display: none;
            }
        }

        &:hover {
            &:after {
                content: '';
                position: relative;
                margin: 0 auto;
                bottom: 0;
                left: 0;
                width: 30px;
                height: 3px;
                background-color: #be4018;
                display: block;
            }
        }

        &.active {
            box-shadow: none;

            &:after {
                content: '';
                position: relative;
                margin: 0 auto;
                bottom: 0;
                left: 0;
                width: 30px;
                height: 3px;
                background-color: #be4018;
                display: block;
            }
        }

        &:after {
            content: '';
            position: relative;
            margin: 0 auto;
            bottom: 0;
            left: 0;
            width: 30px;
            height: 3px;
            background-color: transparent;
            display: block;
        }
    }

    .title {
        @extend .title-sans;
        @extend .title-lg;
        @extend .spacer-sm;
    }

    .gamma-sections {
        @extend .spacer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .line {
        text-align: left;
        margin-bottom: $space;

        [class*="title"] {
            .thickness {
                @extend .sans-serif;
                font-size: 18px;
                color: $orange;
            }
        }
    }

    .products {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px;
    }

    .product {
        flex: 0 1 20%;
        order: 1;
        padding: 20px;
        position: relative;
        transition: all .4s ease-out;

        &:hover {
            @extend .shadow;
            color: white;
            background: $orange;
            cursor: pointer;
            z-index: 10;
        }

        &.open {
            &:hover {
                color: $black;
            }
        }

        img {
            max-width: 100%;
            margin-bottom: $space / 6;
        }

        a {
            font-size: 12px;
        }

        h5 {
            span {
                float: right;
            }
        }

        @media #{$sm-max} {
            flex: 0 1 33%;
        }

        @media (max-width: 767px) {
            flex: 0 1 50%;
        }
    }
    // .product
    .product.open {
        background: $sand;

        &:hover {
            background: $sand;
            box-shadow: none;
        }
    }

    @media #{$sm-max} {
        .gamma-sections {
            .btn {
                min-width: 0;
            }
        }
    }

    @media #{$xs-max} {
        .gamma-sections {
            .btn {
                display: block;
            }
        }
    }
}
// .scheda-gamma
.panaria-usa {
    .scheda-gamma {
        .btn {
            @extend .btn-sanddark;

            &.btn-active {
                @extend .btn-sand;
            }
        }

        .product:hover {
            background-color: $sand;
            color: $black;
        }
    }
}





/* -------------------------------------------------- 
  Caratteristiche Zero.3
-------------------------------------------------- */
.section-caratteristiche-zero3 {
    @extend .section;
    padding-top: 0;
}



/* -------------------------------------------------- 
  Product detail
-------------------------------------------------- */
.product-detail {
    flex: 0 1 100%;
    background: $sand;
    padding: ($space * .75) 20px;
    position: relative;
    display: none;

    .roomvo--button {
        .btn {
            &:after {
                /*background: #333;*/
            }

            #rect {
                /*fill: #333;*/
            }

            &:hover {
                #rect {
                    /*fill: #f6f3eb;*/
                    /*stroke: #be4018;*/
                }

                path {
                    /*fill: #be4018;*/
                }
            }
        }
    }

    .product-detail__text {
        margin-bottom: 10px;
    }

    .btn--with-icon--text {
        display: block !important;
    }

    .x-close {
        position: absolute;
        right: 30px;
        top: 30px;
        opacity: .5;
        transition: opacity .2s;

        &:hover {
            opacity: 1;
        }
    }

    .actions {
        @extend .list-inline;
        @extend .list-reset;
        margin-bottom: 30px;

        a {
            color: $sanddark;
            width: 30px;
            height: 30px;
            display: inline-block;
            font-size: 20px;
            transition: color .2s;

            &:hover {
                color: $black;
            }
        }
    }

    &__image {
        margin-bottom: 30px;

        img {
            max-width: 100%;
        }
    }


    .title {
        @extend .title-sans;
        @extend .spacer;
        // font-size: 60px;
    }

    .formati {
        font-size: 14px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        &-item {
            width: 50%;
            padding-bottom: 30px;
            flex: 1 0 50%;

            & > div {
                display: flex;
                float: left;
            }
        }

        img {
            max-width: 110px;
            margin-right: 10px;
            display: block;
            float: left;
        }

        &-copy {
            padding-right: 10px;
            font-size: 12px;
            float: right;

            p {
                margin-bottom: 0;
            }
        }
    }

    .decori .formati-item {
        width: 25%;
        flex: 0 0 25%;

        @media (max-width: 1500px) {
            width: 33.333%;
            flex: 0 0 33.333%;
        }

        @media (max-width: 1023px) {
            width: 50%;
            flex: 0 0 50%;
        }

        @media (max-width: 768px) {
            width: 10%;
            flex: 0 0 100%;
        }
    }
    // Sfondo a tutta larghezza
    &:after {
        // content: '';
        background: $sand;
        position: absolute;
        width: 100vw;
        height: 100%;
        z-index: -1;
        left: calc(100vw - 100%);
        top: 0;
    }



    @media (max-width: 767px) {
        padding-left: 0;
        padding-right: 0;

        .formati {
            img {
                float: none;
            }
        }
    }

    @media (max-width: 546px) {
        .formati-item {
            flex-basis: 100%;
        }
    }
}
// .product-detail
.product-detail-specialpieces {
    .product-detail__image img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        max-height: 450px;
    }
}

.specialpz-item {
    @extend .spacer;
    text-transform: uppercase;
    font-size: 11px;

    h5 {
        margin-bottom: 10px;

        span {
            background: black;
            background-color: #333333;
            color: #f6f3eb;
            width: 20px;
            display: inline-block;
            text-align: center;
            border-radius: 20px;
            height: 20px;
            padding-top: 4px;
            font-size: 12px;
        }
    }

    th {
        font-size: 10px;
        padding-bottom: 5px;
    }

    td {
        padding-right: 10px;
    }
}
/* -------------------------------------------------- 
  Scheda specs
-------------------------------------------------- */
.ico-spec {
    @include square(80px);
    background-image: url(/img/scheda/specs.png);
    background-size: cover;
    margin: 0 auto;
    display: inline-block;
}

$icons: 'water', 'breaking', 'abrasion', 'expansion', 'stain', 'chemical', 'frost', 'slip', 'flame', 'robinson', 'conductivity', 'joint', 'shade', 'surface-abrasion', 'bond', 'crazing', 'thermalshock';

@for $i from 1 through length($icons) {
    .ico-spec-#{nth($icons, $i)} {
        background-position: 0 #{($i - 1) * -80}px;
    }
}

.scheda-specs h4 {
    margin-top: 60px;
}

.scheda-specs {
    @extend .section;

    .title {
        @extend .title-sans;
        @extend .title-lg;
        @extend .spacer;
        width: 100%;
        text-align: center;
    }

    .table {
        margin-bottom: 0;

        &.table-striped > tbody > tr:nth-of-type(even) {
            background-color: $beige;
        }

        &.table-striped > tbody > tr:nth-of-type(odd) {
            background-color: transparent;
        }

        tr.headings {
            td {
                text-transform: uppercase;
                white-space: nowrap;
                font-size: 16px;
                font-weight: 700;
            }

            @media #{$sm-max} {
                td:nth-child(6),
                td:nth-child(7),
                td:nth-child(8),
                td:nth-child(9),
                td:nth-child(10) {
                    display: none; // Nasconde seconde intestazioni su mobile
                }
            }
        }

        td {
            height: 80px;
            border-top: 0;
            font-size: 12px;
            vertical-align: middle;

            &:nth-child(5) {
                border-right: 4px solid white;
            }
        }

        @media #{$sm-max} {
            td:nth-child(5) {
                border-right: 4px solid white;
            }
        }
    }
}
/* -------------------------------------------------- 
  Scheda download
-------------------------------------------------- */
.scheda-download {
    @extend .section-orange;

    header {
        .title {
            @extend .title-sans;
            @extend .title-lg;
            @extend .spacer;
            width: 100%;
            text-align: center;
        }
    }
}

.panaria-usa .scheda-download {
}
/* -------------------------------------------------- 
  Zero.
-------------------------------------------------- */
.section-zero3 {
    padding-top: 90px;

    header {
        margin-bottom: $space / 2;
        text-align: center;
    }

    .border-orange {
        padding-bottom: $space;
    }

    h4 {
        @extend .spacer;
    }

    &-title {
        transform: translateY(-40px);
        display: inline-block;
        background: white;
        padding: 0 80px;
    }

    .abstract {
        clear: both;
    }

    .img-zero3 {
        max-width: 100%;
    }

    .feature-item {
        text-align: left;
    }

    .caratteristiche-zero3 {
        margin-bottom: $space / 2;
        text-align: center;
    }

    @media #{$md-max} {
        .img-zero3 {
            height: 400px;
        }

        .feature-item {
            text-align: center;
        }

        .caratteristiche-zero3 {
            margin-bottom: $space / 2;

            .features {
                margin: 0;
            }
        }
    }
}
// .section-zero3
/* -------------------------------------------------- 
  Tanti motivi
-------------------------------------------------- */
.scheda-tanti-motivi {
    .title {
        @extend .title-sans;
        @extend .title-lg;
        margin-bottom: $space / 2;
    }
}


.panaria-usa .related {
    header .title {
        @extend .spacer;
        width: 100%;
        text-align: center;
    }
}

.listing-prods > div > .row {
    display: flex;
    flex-wrap: wrap;
}

/* -------------------------------------------------- 
  Full gallery
-------------------------------------------------- */
.full-gallery {
    .slick-next, .slick-prev {
        z-index: 1;
    }
    .slick-dots {
        position: static;
    }
    .video {
        .ico {
            width: auto;
            height: 60px;
            background-color: #f26122;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -30px;
            margin-top: -30px;
            z-index: 3;
            border-radius: 200px;
            display: flex;
            align-items: center;
            padding: 0 30px;

            @media #{$sm-max} {
                margin-left: -80px;
            }

            @media #{$xs-max} {
                margin-left: -90px;
            }

            span {
                font-size: 14px;
                font-weight: 700;
                display: block;
                color: #fff;
                text-transform: uppercase;
            }

            .playicon {
                //position: absolute;
                //margin-top: 13px;
                //margin-left: 20px;
                margin: 0;
                border-left: 28px solid #fff;
                border-right: 18px dashed transparent;
                border-top: 18px dashed transparent;
                border-bottom: 18px dashed transparent;
            }
        }
    }
}

/* Certificazioni */
.certificazioni {
    @extend .section;
    background-color: #f6f3eb;

    .title {
        // @extend .title-lg;
        font-family: 'Source Sans Pro', sans-serif !important;
        margin-bottom: $space / 3;
        text-align: center;
        font-weight: 600 !important;
    }

    .slick-track {
        display: flex;
        align-items: center;
    }

    .slick-dots {
        bottom: -50px;
    }

    article {
        @media #{$sm-max} {
            padding: 0 20px;
        }
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;

        figure {
            box-shadow: none !important;
            margin: 0;

            img {
                max-width: 100px !important;
                height: auto !important;

                @media #{$sm-max} {
                    width: 100% !important;
                    height: auto !important;
                }
            }
        }
    }
}