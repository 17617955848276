.print {
  width: 100%;
  min-width: 100%;
  
  .panaria-logo {
    max-width: 100px;
    margin: 0 auto;
  }
  
  .product-detail {
    padding: 40px 20px;
    
    .product-detail__image {
      max-width: 460px;
      margin: 0 auto;
    }
    
    .product-detail__text {
      padding-top: 40px;
      
      .formati {
        padding-top: 20px;
      }
    }
  }
}