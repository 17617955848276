﻿/* -------------------------------------------------- 
  Header
-------------------------------------------------- */
.faq-header {
  @extend .default-header;
  @extend .spacer-lg;
  
  .title { 
    color: $orange;
  }



}




.faq-list {
  @extend .spacer-lg;
}




.faq-item {

  header {
    font-weight: 600;
    font-size: 16px;
    @extend .spacer-sm;

    &:hover {
      cursor: pointer;
    }

    // Icone + / -
    .fa {
      color: $orange;
      font-size: 12px;
      font-weight: normal;
      &:first-child {
        display: none;
      }
    }

    // Container risposta
    & + div {
      @extend .user-text;
      display: none;
      padding-bottom: $space / 2;
      padding-left: 17px;
    }
  }

  @media #{$xs-max} {
    header {
      
    }
  }

}





