﻿/* -------------------------------------------------- 
  Dettaglio
-------------------------------------------------- */
.project-header {
    padding-top: $space / 2;
    padding-bottom: $space / 2;

    .title {
        color: $orange;
    }
}

.project-gallery {
    background: $sand;
    padding-top: $space / 2;
    padding-bottom: $space / 2;

    .title {
        font-family: 'Source Sans Pro', sans-serif !important;
        font-weight: 600 !important;
        text-align: center;
        margin-bottom: 40px;
        font-size: 32px;
    }
}


.project-body {
    background: $sand;
    padding-top: $space / 2;
    padding-bottom: $space / 2;

    &__text {
        padding: ($space/3) $space;

        .wrap-check {
            .read-more {
                display: none;
            }
        }

        .wrap-more.wrap-check {
            /*max-height: 550px;
            overflow: hidden;
            position: relative;*/

            .read-more {
                display: block;
                height: 150px;
                text-align: center;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                background: -moz-linear-gradient(top, rgba(246,243,235,0) 0%, rgba(246,243,235,0.01) 1%, rgba(246,243,235,1) 77%, rgba(246,243,235,1) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top, rgba(246,243,235,0) 0%,rgba(246,243,235,0.01) 1%,rgba(246,243,235,1) 77%,rgba(246,243,235,1) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, rgba(246,243,235,0) 0%,rgba(246,243,235,0.01) 1%,rgba(246,243,235,1) 77%,rgba(246,243,235,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                &:before {
                    height: 1px;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: 9px;
                    width: 100%;
                    content: "";
                    z-index: 1;
                    display: block;
                    background-color: #a9a7a3;
                }

                span {
                    display: block;
                    position: absolute;
                    left: 0;
                    bottom: 10px;
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 14px;
                    padding: 2px 4px;
                    margin: 0 auto -15px auto;
                    width: 100%;
                    z-index: 2;


                    strong {
                        display: inline-block;
                        padding: 2px 12px;
                        background-color: #f6f3eb;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    &__title {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: $space / 12;
    }

    &__details {
        margin-top: $space / 2;

        [class*="col-"] {
            margin-bottom: $space / 3;
        }

        .text-link {
            white-space: nowrap;
        }
    }

    &__share {
        a {
            color: $black;
            font-size: 24px;
            margin-right: 10px;
        }
    }

    @media #{$md-max} {
        &__text {
            padding-left: 20px;
            padding-right: 20px;
        }
    }


    @media #{$sm-max} {
        &__text {
            padding-left: 0;
            padding-right: 0;
        }
    }
}




/* -------------------------------------------------- 
  Slider
-------------------------------------------------- */
.project-slider {

    &__wrapper {
        position: relative;
    }

    &--modal {
        .slick-prev {
            z-index: 1;
        }
    }

    &--nav {
        @media #{$sm-max} {
            display: none;
        }
    }

    &--big, &--nav {
        .slick {
            &-slide img {
                max-width: 100%;
            }

            &-next, &-prev {
                &:before {
                    color: $black;
                }
            }
        }
    }


    &--big, &--modal {

        .slick-slide {
            overflow: hidden;

            figure {
                position: relative;
                height: 0;
                padding-bottom: 66%;

                @media #{$sm-max} {
                    height: 500px;
                    padding: 0;
                    display: flex;
                    align-items: center;
                }

                & > div {
                    width: 100%;

                    @media #{$sm-max} {
                        height: auto;
                        position: relative;
                    }
                }
            }
            // Per immagini verticali
            &.vertical-image {
                figure {
                    padding-bottom: 86%;
                    //margin: 0 200px;

                    @media #{$sm-max} {
                        margin: 0;
                    }

                    img {
                        width: auto;
                        margin: 0 auto;
                        height: 80%;

                        @media #{$sm-max} {
                            object-fit: cover;
                            height: 100%;
                            width: 100%;
                        }
                    }
                }

                figure > div {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }

                img {
                    height: 80%;
                }
            }
        }
        // .slick-slide
    }
    // &--big, &--modal
    &--big {
        // @extend .shadow;
        // margin-bottom: $space / 4;
        // background: white;
        height: 0;
        padding-bottom: calc(66% + 40px);
        overflow: hidden;

        .slick-slide {
            padding-bottom: 40px;
            cursor: pointer;

            figure {

                & > div {
                    display: inline-block;
                    margin: 0 auto;
                    box-shadow: 0px 40px 50px -40px rgba(0, 0, 0, 0.4);
                }
            }
        }

        .slick-prev {
            z-index: 1;
        }

        .video {
            .ico {
                width: auto;
                height: 60px;
                background-color: #f26122;
                position: absolute;
                top: 50%;
                left: 40%;
                margin-left: -30px;
                margin-top: -30px;
                z-index: 3;
                border-radius: 200px;
                display: flex;
                align-items: center;
                padding: 0 30px;

                @media #{$sm-max} {
                    margin-left: -80px;
                }

                @media #{$xs-max} {
                    margin-left: -90px;
                }

                span {
                    font-size: 14px;
                    font-weight: 700;
                    display: block;
                    color: #fff;
                    text-transform: uppercase;
                }

                .playicon {
                    //position: absolute;
                    //margin-top: 13px;
                    //margin-left: 20px;
                    margin: 0;
                    border-left: 28px solid #fff;
                    border-right: 18px dashed transparent;
                    border-top: 18px dashed transparent;
                    border-bottom: 18px dashed transparent;
                }
            }
        }
    }



    &--nav {
        .slick {
            &-list {
                margin-right: -10px;
            }

            &-slide {
                margin-right: 10px;
                opacity: 0.5;
            }

            &-current {
                opacity: 1;
            }
        }

        a {
            display: block;
            background-position: center center;
            background-size: cover;
            height: 0;
            padding-bottom: 66%;
        }

        @media #{$xs-max} {
            //display: none;
        }
    }

    &--modal {
        //background: $sand;
        height: 0;
        padding-bottom: 66%;

        @media #{$xs-max} {
            height: 500px;
        }

        .slick {
            &-slide {
                position: relative;
            }
        }
    }
}
//.project-slider


.social-share {
    .title {
        font-weight: 600;
    }
}



/* -------------------------------------------------- 
  Label on images
-------------------------------------------------- */
.image-label {
    position: absolute;

    @media #{$xs-max} {
        display: none;
    }

    a {
        @extend .text-link;
        color: $black;
        position: absolute;
        white-space: nowrap;
        font-size: 12px;
    }

    a, span {
        color: $black;
        font-weight: 600;
        position: absolute;
        white-space: nowrap;
        font-size: 12px;
    }

    svg {
        line, circle {
            fill: transparent;
            stroke-width: 1px;
            stroke: $black;
        }

        circle {
            animation: fade 2s ease-out alternate;
        }
    }

    &--white {
        a, span {
            color: white;
        }

        svg {
            line, circle {
                stroke: white;
            }
        }
    }
}


@keyframes circle-anim {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}



/* -------------------------------------------------- 
  Invia progetto
-------------------------------------------------- */
.send-project {
    @extend .section;
    background: $sand;

    .title, .copy {
        margin-bottom: $space / 3;
    }

    .cta {
        font-weight: 600;
        text-decoration: underline;
    }
}

/* -------------------------------------------------- 
  Project Content
-------------------------------------------------- */
.project-slider {
    background-color: #be4018;
    color: white;
    padding: 90px 0;
    margin-bottom: 60px;
}

/* -------------------------------------------------- 
  Project Content
-------------------------------------------------- */
.project-content {
    background-color: #f6f3eb;
    padding: 60px 0;

    .full-width-slider {

        .item {
            padding-bottom: 40px;
            position: relative;



            .immagine {
                margin-bottom: 30px;

                img {
                    width: 100%;
                    box-shadow: 0px 40px 60px -50px rgba(0, 0, 0, 0.4);
                }
            }

            .abstract {
                text-align: center;
                /*font-family: 'Voor', serif;
                font-size: 20px;*/
                font-weight: 600;
            }
        }
    }
    /*.wrap-txt-alternate {
        position: relative;

        &:before {
            content: "";
            width: 200px;
            height: 30px;
            background: $orange;
            position: absolute;
            bottom: -30px;
            right: 0px;
            clear: both;
            z-index: 1;

            @media screen and (max-width: 767px) {
                display: none;
            }
        }
    }*/

    .img-txt-alternate {
        z-index: 2;

        .item {
            .video {
                iframe {
                    width: 100%;
                    height: 390px;
                }
            }

            .immagine {
                margin-bottom: 30px;
                position: relative;

                img {
                    width: 100%;
                    //box-shadow: 0px 40px 60px -50px rgba(0, 0, 0, 0.4);
                    position: relative;
                    z-index: 2;
                }

                &:before {
                    content: "";
                    width: 100px;
                    height: 10px;
                    background: $orange;
                    position: absolute;
                    bottom: -10px;
                    right: 0;
                    clear: both;
                    z-index: 1;

                    @media screen and (max-width: 767px) {
                        display: none;
                    }
                }
            }

            .abstract {
                text-align: center;
                /*font-family: 'Voor', serif;
                font-size: 20px;*/
                font-weight: 600;
            }
        }
    }

    .slick-prev {
        z-index: 1;
    }

    .slick-dots {
        bottom: -35px;

        li {
            width: 10px;
            height: 10px;

            button {
                border: 1px solid #f26122;
                border-radius: 50%;
                padding: 0;
                width: 10px;
                height: 10px;

                &:before {
                    content: '';
                    opacity: 1;
                    position: relative;
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    .slick-dots li.slick-active button {
        background: #f26122 !important;
    }

    .ico {
        width: 60px;
        height: 60px;
        background-color: #f26122;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -30px;
        margin-top: -30px;
        z-index: 3;
        border-radius: 200px;

        .playicon {
            position: absolute;
            margin-top: 13px;
            margin-left: 20px;
            border-left: 28px solid #fff;
            border-right: 18px dashed transparent;
            border-top: 18px dashed transparent;
            border-bottom: 18px dashed transparent;
        }
    }
}