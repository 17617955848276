﻿/* -------------------------------------------------- 
  Global
-------------------------------------------------- */
.azienda-header {
    @extend .spacer;
    text-align: center;
}


.azienda-title {
    font-size: 56px;
    font-weight: 600;

    @media #{$sm-max} {
        font-size: 32px;
    }

}

.azienda-storia, .azienda-innovazione {
    padding: 90px 0;

    @media #{$sm-max} {
       padding: 60px 0;
    }
}
/* -------------------------------------------------- 
  Hero
-------------------------------------------------- */
.azienda-hero {
    max-height: 650px;
    min-height: 400px;
}



/* -------------------------------------------------- 
  Storia
-------------------------------------------------- */
.azienda-storia {
    @extend .section;

    .title-quotes {
        @extend .title-quotes--orange;
    }
}




/* -------------------------------------------------- 
  Timeline
-------------------------------------------------- */
.azienda-timeline {
    @extend .section;
    background: $sand;
    position: relative;

    .title-azienda {
        @extend .spacer-lg;
    }

    .title-quotes {
        @extend .title-quotes--orange;
    }
    // Riga arancio di sfondo
    /*&:after {
    content: '';
    background: $orange;
    width: 100%;
    height: 20px;
    position: absolute;
    left: 0;
    top: 50%;
  }*/
    .timeline-image {
        position:relative;
        &:before {
            content: "";
            width: 100px;
            height: 10px;
            background: $orange;
            position: absolute;
            bottom: -10px;
            right: 0;
            clear: both;
            z-index: 1;

            @media screen and (max-width: 767px) {
                display: none;
            }
        }
    }
}


.timeline {

    &-slider {
        z-index: 2;
        max-width: 100%;
    }

    &-item {
        $padding: 60px;
        width: 560px;
        padding-bottom: 50px;
        padding-left: $padding;
        padding-right: $padding;

        &:focus {
            outline: 0;
        }

        &.slick-active {
            // opacity: 1;
        }


        & > div {
            display: flex;
            flex-direction: column;
        }

        &:nth-child(even) {
            .timeline-text {
                margin-top: 30px;
                margin-bottom: 0;
            }

            .timeline-image {
                order: -1;
            }
        }
    }

    &-text {
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 30px;
    }

    &-year {
        font-size: 52px;
        font-weight: 500;
        margin-bottom: 0;
    }

    &-copy {
        font-size: 13px;
    }

    &-image {
        @extend .shadow;
        height: 0;
        padding-bottom: 100%;

        img {
            max-width: 100%;
        }
    }

    &-nav {
        margin-top: 60px;

        ul {
            @extend .list-reset;
            @extend .list-inline;
            line-height: 3;
        }

        li {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                background: $sanddark;
                right: -10px;
                top: 50%;
                width: 24px;
                height: 1px;
            }

            &:last-child:after {
                content: none;
            }
        }

        a {
            color: $black;
            margin: 0 20px;

            &.active {
                color: $orange;

                &:after {
                    width: 100%;
                    background-color: $orange;
                }

                &:focus {
                    text-decoration: none;
                }
            }
        }
    }
    // .timeline-nav
    @media #{$sm-max} {
        &-item {
            width: 480px;

            & > div {
                display: block;
            }
        }

        &-image {
            display: inline-block;
        }

        &-text,
        &-nav {
            margin-top: 0;
        }
    }

    @media #{$xs-max} {
        &-item {
            width: 100%;
            max-width: 100%;
            padding-bottom: 50px;
            padding-left: 15px;
            padding-right: 15px;
        }

        &-nav {
            li:after {
                right: 1px;
                width: 4px;
            }

            a {
                margin: 0 5px;
            }
        }
    }
}



/* -------------------------------------------------- 
  Profilo
-------------------------------------------------- */
.icon {
    @include square(110px);
    @extend .spacer;
    background-image: url(/img/azienda/plus.png);
    background-size: cover;
    margin: 0 auto;
    border-radius: 110px;
    display: inline-block;

    @media #{$xs-max} {
        @include square(100px);
        border-radius: 100px;
    }
}



$icons: 'vaso', 'bersaglio', 'razzo', 'cuore';

@for $i from 1 through length($icons) {
    .icon-#{nth($icons, $i)} {
        background-position: -#{$i * 110}px 0;

        @media #{$xs-max} {
            background-position: -#{$i * 100}px 0;
        }
    }
}


.azienda-profilo {
    @extend .section-orange;

    .azienda-video {
        @extend .spacer;
    }

    .azienda-plus {
        font-size: 13px;
    }


    @media #{$sm-max} {

        .title {
            font-size: 24px;
            line-height: 1.3;
        }

        .azienda-plus {
            font-size: 16px;
            margin-bottom: 30px;
        }
    }
}


/* -------------------------------------------------- 
  Innovazione
-------------------------------------------------- */
.azienda-innovazione {
    @extend .section;

    .title-quotes {
        @extend .title-quotes--orange;
    }
}


/* -------------------------------------------------- 
  Virtual tour
-------------------------------------------------- */
.azienda-virtual-tour {
    @extend .section;

    .title-quotes {
        @extend .title-quotes--orange;
    }
}


/* -------------------------------------------------- 
  Qualità
-------------------------------------------------- */
.azienda-qualita {
    @extend .section-orange;
    // font-size: 15px;
    .title {
        @extend .spacer;
        font-size: 56px;

        @media #{$sm-max} {
            font-size: 32px;
        }
    }

    &-intro {
        text-align: left;

        .title-intro {
            margin-top: $space * .2;
            margin-bottom: $space * .25;
            font-size: 32px;

            @media #{$sm-max} {
                font-size: 24px;
            }
        }

        p {
            /*padding-left: 25%;*/
            margin-bottom: 20px;
        }
    }



    img {
        max-width: 100%;
    }

    .btn {
        color: $orange;
    }

    &-cta {
        padding-top: 60px;
    }

    &__text {
        @extend .section;
        display: none;

        p {
            padding: 0 25px;
        }
    }

    @media (max-width: 1299px) {
        &-intro {
            text-align: left;

            .title-intro {
                margin-top: $space * .1;
                margin-bottom: $space * .25;
            }
        }
    }

    @media #{$md-max} {
        &-intro {
            text-align: left;

            .title-intro {
                margin-top: $space * .1;
                margin-bottom: $space * .25;
            }

            p {
                padding-left: 0;
            }
        }
    }

    @media #{$sm-max} {
    }

    @media #{$xs-max} {
        &__text {
            text-align: left;

            p {
                padding: 0;
            }
        }

        .btn {
            width: 100%;
            margin-bottom: 30px;
        }
    }
}
